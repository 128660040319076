import {get, post, del, put, getFile } from "../http"

export default {
    // 入库单
    warehouseInSource(p) {
        return get('api/warehouse/in/source', p);
    },
    warehouseInList(p) {
        return get('api/warehouse/in/handle', p);
    },
    warehouseInDetail(p) {
        return get('api/warehouse/in/handle/' + p.id, p);
    },
    warehouseInAction(p) {
        return post('api/warehouse/in/handle/' + p.id, p);
    },
    warehouseInExport(p) {
        return getFile('api/warehouse/in/handle_excel' + p.id, p);
    },
    //入库日志
    warehouseInLogList(p) {
        return get('api/warehouse/in/detailed', p);
    },
    warehouseInLogDetail(p) {
        return get('api/warehouse/in/detailed/' + p.id, p);
    },
    warehouseInLogExport(p) {
        return getFile('api/warehouse/in/detailed_excel' + p.id, p);
    },


}