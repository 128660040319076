export default {
  data () {
    return {
      Modals: {
      }
    }
  },
  created () {
    for (let k in this.Modals) {
      if (!this._isType(this.Modals[k], 'Object')) continue
      this._modalInit(k)
    }
  },
  methods: {
    // 触发开启弹层
    async ModalInit (options = {}) {
      if (!this.Modals[options.modalName]) this._modalInit(options.modalName)

      // 每次必须重置
      this['Modals'][options.modalName] = {
        loading: false,
        inited: true,
        show: true,
        modalTitle: options.modalTitle || this['Modals'][options.modalName]['modalTitle'] || options.modalName,
        modalWidth: this['Modals'][options.modalName]['modalWidth'] || (this.$vue2xcrudConfig && this.$vue2xcrudConfig.model && this.$vue2xcrudConfig.model.width) || ''
      }

      for (var k in options) {
        this.$set(this.Modals[options.modalName], k, options[k])
      }
    },
    // 保存关闭弹窗
    async ModalSubmit (options = {}) {
      if ((!options.modal && !options.modalName) || !options.modalAction) return false

      let modal = options.modal || this['Modals'][options.modalName]

      if (this.$vue2xcrudConfig && this.$vue2xcrudConfig.console == true) console.log('ModalSubmit >> start')
      modal['loading'] = true
      let result = await options.modalAction // options.modalAction 必须返回 Promise 对象
      if (result) {
        modal['show'] = false
        options.modalCallback && this._isType(options.modalCallback, 'Function') && options.modalCallback.call(this, result)
      } else {
        if (this.$vue2xcrudConfig && this.$vue2xcrudConfig.console == true) console.log('ModalSubmit >> catch')
        modal['loading'] = false  // 此处不能放到 el-dialog @closed 中处理，否则验证表单失败后依然 loading
      }
      if (this.$vue2xcrudConfig && this.$vue2xcrudConfig.console == true) console.log('ModalSubmit >> end')
    },
    _modalInit (item) {
      if (!this._isType(this.Modals[item], 'Object')) this.$set(this.Modals, item, {})
      this.$set(this.Modals[item], 'loading', false)
      this.$set(this.Modals[item], 'inited', false)
      this.$set(this.Modals[item], 'show', false)
    },
    _isType (obj, type) {
      return Object.prototype.toString.call(obj) === '[object ' + type + ']'
    }
  },
}