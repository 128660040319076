import {get, post, del, put, getFile } from "../http"

export default {
    // 
    getConfig(p) {
        return get('api/set_up', p);
    },
    setConfig(p) {
        return post('api/set_up', p);
    }
}