import Layout from '@/layout'
import RouterView from '@/layout/routerView'


export default {
  path: '',
  meta: { roles: [3], title: '资金', icon: 'icon-zijin', type: 'menu' },
  component: Layout,
  children: [
    {
      path: 'collection', name: '收款单',
      meta: { type: 'menu' },
      component: () => import('@/views/资金/收款单/收款单')
    },
    {
      path: 'collection/add', name: '收款单新增',
      meta: { type: 'need' },
      component: () => import('@/views/资金/收款单/收款单新增/收款单新增')
    },
    {
      path: 'collection/detail', name: '收款单详情',
      meta: { type: 'need' },
      component: () => import('@/views/资金/收款单/收款单详情/收款单详情')
    },
    {
      path: 'payment', name: '付款单',
      meta: { type: 'menu' },
      component: () => import('@/views/资金/付款单/付款单')
    },
    {
      path: 'payment/add', name: '付款单新增',
      meta: { type: 'need' },
      component: () => import('@/views/资金/付款单/付款单新增/付款单新增')
    },
    {
      path: 'payment/detail', name: '付款单详情',
      meta: { type: 'need' },
      component: () => import('@/views/资金/付款单/付款单详情/付款单详情')
    },
    {
      path: 'otherFlow', name: '其他收支',
      meta: { type: 'menu' },
      component: () => import('@/views/资金/其他收支/其他收支')
    },
    {
      path: 'other_coin/add', name: '其他收支新增',
      meta: { type: 'need' },
      component: () => import('@/views/资金/其他收支/其他收支新增/其他收支新增')
    },
    {
      path: 'cashFlow', name: '资金流水',
      meta: { type: 'menu' },
      component: () => import('@/views/资金/资金流水/资金流水')
    }
  ]
}
