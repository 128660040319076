import {get, post, del, put } from "../http"
export default {
    // 操作日志
    getActionLog(p) {
        return get('api/setup/operationlog', p);
    },
    // 修改密码
    changePass(p) {
        return post('api/staff/change_password', p);
    }

}