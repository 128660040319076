import Layout from '@/layout'
import RouterView from '@/layout/routerView'


export default {
  path: '',
  meta: { title: '库存', icon: 'icon-kucun', type: 'menu' },
  component: Layout,
  children: [
    {
      path: 'stockInit', name: '期初库存',
      meta: { type: 'menu' },
      component: () => import('@/views/库存/期初库存/期初库存')
    },
    {
      path: '',
      meta: { title: '库存管理', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'stockQuery', name: '库存查询',
          meta: { type: 'menu' },
          component: () => import('@/views/库存/库存管理/库存查询/库存查询')
        },
        {
          path: 'stockflow', name: '库存流水',
          meta: { type: 'menu' },
          component: () => import('@/views/库存/库存管理/库存流水/库存流水')
        },
        {
          path: 'stockflow/:id', name: '库存流水传参',
          meta: { type: 'need' },
          component: () => import('@/views/库存/库存管理/库存流水/库存流水')
        }
      ]
    },
    {
      path: 'stockCheck', name: '库存盘点',
      meta: { type: 'menu' },
      component: () => import('@/views/库存/库存盘点/库存盘点')
    },
    {
      path: 'inventory/add', name: '库存盘点新增',
      meta: { type: 'need' },
      component: () => import('@/views/库存/库存盘点/库存盘点新增/库存盘点新增')
    },
    {
      path: 'inventory/detail', name: '库存盘点详情',
      meta: { type: 'need' },
      component: () => import('@/views/库存/库存盘点/库存盘点详情/库存盘点详情')
    },

    {
      path: 'stockAllot', name: '库存调拨',
      meta: { type: 'menu' },
      component: () => import('@/views/库存/库存调拨/库存调拨')
    },
    {
      path: 'allot/add', name: '库存调拨新增',
      meta: { type: 'need' },
      component: () => import('@/views/库存/库存调拨/库存调拨新增/库存调拨新增')
    },
    {
      path: 'allot/detail', name: '库存调拨详情',
      meta: { type: 'need' },
      component: () => import('@/views/库存/库存调拨/库存调拨详情/库存调拨详情')
    },

    {
      path: 'stockLend', name: '借出',
      meta: { type: 'menu' },
      component: () => import('@/views/库存/借出/借出')
    },
    {
      path: 'lend/add', name: '借出新增',
      meta: { type: 'need' },
      component: () => import('@/views/库存/借出/借出新增/借出新增')
    },
    {
      path: 'lend/detail', name: '借出详情',
      meta: { type: 'need' },
      component: () => import('@/views/库存/借出/借出详情/借出详情')
    },
    {
      path: 'lend/return', name: '借出归还',
      meta: { type: 'need' },
      component: () => import('@/views/库存/借出/借出归还/借出归还')
    },

    {
      path: 'stockBorrow', name: '借入',
      meta: { type: 'menu' },
      component: () => import('@/views/库存/借入/借入')
    },
    {
      path: 'borrow/add', name: '借入新增',
      meta: { type: 'need' },
      component: () => import('@/views/库存/借入/借入新增/借入新增')
    },
    {
      path: 'borrow/detail', name: '借入详情',
      meta: { type: 'need' },
      component: () => import('@/views/库存/借入/借入详情/借入详情')
    },
    {
      path: 'borrow/return', name: '借入归还',
      meta: { type: 'need' },
      component: () => import('@/views/库存/借入/借入归还/借入归还')
    },

    {
      path: '',
      meta: { title: '入库', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'stockIn', name: '入库单管理',
          meta: { type: 'menu' },
          component: () => import('@/views/库存/入库/入库单管理/入库单管理')
        },
        {
          path: 'warehouse_in/add', name: '入库单新增',
          meta: { type: 'need' },
          component: () => import('@/views/库存/入库/入库单管理/入库单新增/入库单新增')
        },
        {
          path: 'warehouse_in/detail', name: '入库单详情',
          meta: { type: 'need' },
          component: () => import('@/views/库存/入库/入库单管理/入库单详情/入库单详情')
        },
        {
          path: 'stockInLog', name: '入库单明细',
          meta: { type: 'menu' },
          component: () => import('@/views/库存/入库/入库单明细/入库单明细')
        },
        {
          path: 'warehouse_in_log/detail', name: '入库单明细详情',
          meta: { type: 'need' },
          component: () => import('@/views/库存/入库/入库单明细/入库单明细详情/入库单明细详情')
        }

      ]
    },
    {
      path: '',
      meta: { title: '出库', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'stockOut', name: '出库管理',
          meta: { type: 'menu' },
          component: () => import('@/views/库存/出库/出库管理/出库管理')
        },
        {
          path: 'warehouse_out/add', name: '出库管理-出库',
          meta: { type: 'need' },
          component: () => import('@/views/库存/出库/出库管理/出库管理-出库/出库管理-出库')
        },
        {
          path: 'warehouse_out/detail', name: '出库管理-详情',
          meta: { type: 'need' },
          component: () => import('@/views/库存/出库/出库管理/出库管理-详情/出库管理-详情')
        },

        {
          path: 'stockOutLog', name: '出库明细',
          meta: { type: 'menu' },
          component: () => import('@/views/库存/出库/出库明细/出库明细')
        },
        {
          path: 'warehouse_out_log/detail', name: '出库明细-详情',
          meta: { type: 'need' },
          component: () => import('@/views/库存/出库/出库明细/出库明细-详情/出库明细-详情')
        }
      ]
    }
  ]
}


