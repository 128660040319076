<template>
  <div class="AppLayout" :class="appSide">
    <div class="AppLeftWrap">
      <AppLeft @reload="reload" @toggle="toggle" />
    </div>
    <div class="AppRightWrap">
      <AppTop />
      <div class="AppCon">
        <!-- <transition name="fade-transform" mode="out-in"> -->
          <!-- 目前做页面缓存有坑，编辑内页逻辑被缓存后可能导致功能迁移不全面 -->
          <!--
          <keep-alive>
            <router-view v-if="isReload" :key="$route.name" />
          </keep-alive>
          -->
        <!-- </transition> -->
        <router-view v-if="isReload" :key="$route.name" />
      </div>
    </div>
    <Socket />
  </div>
</template>

<script>

import AppLeft from './AppLeft'
import AppTop from './AppTop'

export default {
  components: {
    AppLeft,
    AppTop,
    Socket: () => import('./components/Socket')
  },
  data () {
    return {
      isReload: true,
      appSide: localStorage.getItem('AppSide') || 'AppLeftSide'
    }
  },
  created() {
    this.$axios(this.$APIHost + '/api/set_up').then(res => {
      let setUpInfo = {}
      res.data.map((item, index) => {
        setUpInfo[item.name] = item.value
      })
      this.$store.state.SetUpInfo = setUpInfo
      // store.commit('SetUpInfo', setUpInfo)
    })
  },
  methods: {
    reload() {
      this.isReload = false
      this.$nextTick(() => this.isReload = true)
    },
    toggle() {
      this.appSide = this.appSide == 'AppLeftSide' ? 'AppRightSide' : 'AppLeftSide'
      localStorage.setItem('AppSide', this.appSide)
    }
  },
}
</script>

<style lang="scss" scoped>

  .AppLayout.AppRightSide {
    /deep/ .AppLeft .menu .menuItem .child {left:unset;right:70px;}
    /deep/ .AppLeft .btns .qrcode .qrcodeImg {left:unset;right:70px;}
  }
</style>