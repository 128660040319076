import Vue from 'vue'
import Vuex from 'vuex'
import { resetRouter } from '@/router'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    UserInfo: '',
    HasPermission: false,
    SetUpInfo: {},
    CurrentMenuTab: '',
    MenuTabs: [],
    CURRENT_ORDER_LIST: []
  },
  setOrderList(o) {
      this.state.CURRENT_ORDER_LIST = o;
  },
  getOrderList() {
      return this.state.CURRENT_ORDER_LIST;
  },
  mutations: {
    SetCurrentMenuTab(state, payload) {
      state.CurrentMenuTab = payload
    },
    SetMenuTabs(state, payload) {
      state.MenuTabs = payload
    },
    SetUpInfo(state, payload) {
      state.SetUpInfo = payload
    },
    Logout(state, payload) {
      localStorage.setItem('uid', '')
      localStorage.setItem('userinfo', null)
      localStorage.setItem('authorization-bearer', null)
      localStorage.setItem('MenuTabs', null)  // 清空菜单便签，必须设置为 null，否则JSON格式会报错
      sessionStorage.setItem('ScanPrintList', null)
      state.MenuTabs = []
      state.HasPermission = false // 重置权限标识
      state.UserInfo = ''
      resetRouter() // 重置路由
    }
  }
})
export default store