import {get, post, del, put } from "../http";
export default {
    // 会员列表
    memberList(p) {
        return get('/api/commerce_member', p);
    },
    // 会员详情
    memberDetail(p) {
        return get('/api/commerce_member/' + p.id);
    },
    // 会员编辑
    memberEdit(p) {
        return put('/api/commerce_member/' + p.id, p);
    },
    // 等级数据源
    levelSource(p) {
        return get('/api/commerce_member_grade_ordinary', p);
    },
    // 等级列表
    levelList(p) {
        return get('/api/commerce_member_grade', p);
    },
    addLevel(p) {
        return post('/api/commerce_member_grade', p);
    },
    editLevel(p) {
        return put('/api/commerce_member_grade/' + p.id, p);
    },
    deleteLevel(p) {
        return del('/api/commerce_member_grade/' + p.id, p);
    },
    // 等级类型数据源，普通，积分
    levelTypeSource(p) {
        return get('/api/commerce_member_grade_types', p);
    },
    // 积分配置
    scorelList(p) {
        return get('/api/commerce_member_integral', p);
    },
    addScore(p) {
        return post('/api/commerce_member_integral', p);
    },
    editScore(p) {
        return put('/api/commerce_member_integral/' + p.id, p);
    },
    deleteScore(p) {
        return del('/api/commerce_member_integral/' + p.id, p);
    },
    // 获取积分的类型
    scoreTypeList(p) {
        return get('/api/commerce_member_integral_types', p);
    }

}