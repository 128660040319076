import {get, post, del, put } from "../http"
export default {
    // 保存模版
    printSave: (p) => {
        return post('/api/print/save', p);
    },
    // 模版列表
    printList: (p) => {
        return get('/api/print/index', p);
    },

    // 根据模板类型获取所有模板
    getPirntTplByType(p) {
        return get('/api/print/template', p);
    },
    // 获取模版类型
    typeList: (p) => {
        return get('/api/print/type', p);
    },
    // 用户是否有权限创建连续模版
    getTplAuth: (p) => {
        return get('/api/print/expand/show', p);
    },
    //获取模版详情
    getPrint: (p) => {
        return get('/api/print/get', p);
    },
    // 删除模版
    rmTpl: (p) => {
        return get('/api/print/del', p);
    },
    shareConfirm(p) {
        return put('/api/print/shareconfirm/' + p.code);
    },
    // 获取打印配置
    getPrintSet(p) {
        return get('/api/strategy/shops/print_seting');
    },
    // 保存配置
    setPrintSet(p) {
        return post('/api/strategy/shops/print_seting', p);
    },
    checkTplCode(p) {
        let code = p.code;
        return put('/api/print/expand/' + code, p);
    }

}