import Layout from '@/layout'
import RouterView from '@/layout/routerView'


export default {
  path: '',
  meta: { title: '采购', icon: 'icon-caigou1', type: 'menu' },
  component: Layout,
  children: [
    {
      path: 'purchase', name: '采购单',
      meta: { type: 'menu' },
      component: () => import('@/views/采购/采购单/采购单')
    },
    {
      path: 'purchase/add', name: '采购单新增',
      meta: { type: 'need' },
      component: () => import('@/views/采购/采购单/采购单新增/采购单新增')
    },
    {
      path: 'purchase/detail', name: '采购单详情',
      meta: { type: 'need' },
      component: () => import('@/views/采购/采购单/采购单详情/采购单详情')
    },
    {
      path: 'purchaseReturn', name: '采购退货申请单',
      meta: { type: 'menu' },
      component: () => import('@/views/采购/采购退货申请单/采购退货申请单')
    },
    {
      path: 'purchase_tui/add', name: '采购退货申请单新增',
      meta: { type: 'need' },
      component: () => import('@/views/采购/采购退货申请单/采购退货申请单新增/采购退货申请单新增')
    },
    {
      path: 'purchase_tui/detail', name: '采购退货申请单详情',
      meta: { type: 'need' },
      component: () => import('@/views/采购/采购退货申请单/采购退货申请单详情/采购退货申请单详情')
    }
  ]
}


