const files = require.context('@/interface/apis', false, /\.js$/);
let arr = [];
files.keys().forEach(key => {
  arr = arr.concat(files(key).default);
});
let obj = {};
arr.forEach((ele, key) => {
  for (let k in ele) {
    obj[k] = ele[k];
  }
});


export default obj