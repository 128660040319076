import {get, post, del, put, getFile } from "../http"

export default {
    // 入库单
    warehouseOutSource(p) {
        return get('api/warehouse/out/source', p);
    },
    warehouseOutList(p) {
        return get('api/warehouse/out/handle', p);
    },
    warehouseOutDetail(p) {
        return get('api/warehouse/out/handle/' + p.id, p);
    },
    warehouseOutAction(p) {
        return post('api/warehouse/out/handle/' + p.id, p);
    },
    warehouseOutExport(p) {
        return getFile('api/warehouse/out/handle_excel' + p.id, p);
    },
    //入库日志
    warehouseOutLogList(p) {
        return get('api/warehouse/out/detailed', p);
    },
    warehouseOutLogDetail(p) {
        return get('api/warehouse/out/detailed/' + p.id, p);
    },
    warehouseOutLogExport(p) {
        return getFile('api/warehouse/out/detailed_excel' + p.id, p);
    },


}