import {get, post, del, put, getFile } from "../http"
export default {
    // 销售明细表
    data_analysis_all(p) {
        return get('api/report/data_analysis/generalization', p);
    },
    data_analysis_all_source(p) {
        return get('api/report/data_analysis/achievement_source', p);
    },
    // 销售汇总表(按类别)
    data_analysis_detail(p) {
        return get('api/report/data_analysis/achievement', p);
    },
    // 销售汇总表(按类别)
    sale_report_cate_excel(p) {
        return getFile('api/report/data_analysis/achievement_excel', p);
    }

}