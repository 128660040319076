import {get, post, del, put } from "../http"

export default {
    // 保存员工
    staffAdd(p) {
        return post('/api/staff/handle', p);
    },
    // 员工列表
    staffList(p) {
        return get('api/staff/handle', p);
    },
    // 员工详情
    staffDetail(p) {
        return get('api/staff/handle/' + p.id, p);
    },
    staffSave(p) {
        return put('api/staff/handle/' + p.id, p);
    },
    // 员工删除
    staffDelete(p) {
        return del('api/staff/handle/' + p.id, p);
    },
    // 所有可以分配的权限
    allAuthList() {
        return get('api/staff/bind_source');
    },
    // 保存员工的权限
    staffAuthSave(p) {
        return post('api/staff/bind/' + p.id, p);
    }
}