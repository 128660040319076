<template>
  <div :class="['loadingBox', { 'show': Show }]">
    <div class="loadingText">{{ Msg }}<i v-loading="true" element-loading-spinner="el-icon-loading" /></div>
  </div>
</template>

<script>
export default {
  props: {
    Show: { type: Boolean, dafault: true },
    Msg: { type: String, dafault: '' }
  }
}
</script>

<style lang="scss" scoped>

  .loadingBox {position:absolute;top:0;left:0;right:0;bottom:0;z-index:-1;display:flex;justify-content:center;align-items:center;
    &.show {z-index:99;
      .loadingText {opacity:1;}
    }
    .loadingText {opacity:0;transition:all .6s;display:flex;padding:15px 20px 15px 20px;font-size:18px;color:#fff;background-color:red;box-shadow:0 0 5px #bbb;border-radius:8px;
      i {margin:18px 18px 0 10px;}
      /deep/ .el-loading-spinner i {color:#fff;}
    }
  }
</style>
