import Vue from 'vue'
import CryptoJS from 'crypto-js'
import { $APIHost } from '@/imports/axios'

const $app = {
  loadingMsg: {
    login: '雇主你好，总管正坐火箭过来，请稍等',
    dataInit: '总管正在准备数据，雇主请稍等',
    dataSubmit: '总管正在处理数据，马上就好请稍等'
  },
  regExp: {
    phone: /^1[3|4|5|7|8][0-9]{9}$/
  },
  pwdEncrypt(txt) {
    txt = CryptoJS.DES.encrypt(txt, CryptoJS.enc.Utf8.parse('erp'), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).ciphertext
    return CryptoJS.enc.Base64.stringify(txt)
  },
  getToken() {
    return localStorage.getItem('authorization-bearer')
  },
  fen2yuan(v) {
    return ((v / 100).toFixed(2) * 1) || 0
  },
  yuan2fen(v) {
    return (v * 100) || 0
  },
}

Vue.prototype.$app = $app

export default $app