import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

/*
ElementUI.Pagination.props.pageSize.default = DEFAULT_CONFIG.pageSize
ElementUI.Pagination.props.layout.default = DEFAULT_CONFIG.pageLayout
ElementUI.Pagination.props.pageSizes.default = () => DEFAULT_CONFIG.pageSizes

ElementUI.Table.props.border.default = true
ElementUI.Table.props.stripe.default = true
ElementUI.Table.props.size.default = DEFAULT_CONFIG.defaultSize
ElementUI.TableColumn.props.showOverflowTooltip.default = true
ElementUI.TableColumn.props.align.default = 'center'
*/


ElementUI.Table.props.stripe = { default: true, type: Boolean }
//ElementUI.TableColumn.props.showOverflowTooltip = { default: true, type: Boolean }


Vue.use(ElementUI, {
  size: 'small'
})