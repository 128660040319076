import {get, post, del, put } from "../http"

export default {
    // 添加供应商
    supplierAdd(p) {
        return post('/api/supplier/handle', p);
    },
    supplierList(p) {
        return get('/api/supplier/handle', p);
    },
    // 供应商详情
    supplierDetail(p) {
        return get('/api/supplier/handle/' + p.id, p);
    },
    // 修改供应商
    supplierSave(p) {
        return put('/api/supplier/handle/' + p.id, p);
    },
    // 添加供应商
    supplierDelete(p) {
        return del('/api/supplier/handle/' + p.id, p);
    },

    // 
    // 添加供应商分类
    supplierCateAdd(p) {
        return post('/api/supplier/supplierclassify', p);
    },
    supplierCateList(p) {
        return get('/api/supplier/supplierclassify', p);
    },
    // 供应商分类详情
    supplierCateDetail(p) {
        return get('/api/supplier/supplierclassify/' + p.id, p);
    },
    // 修改供应商分类
    supplierCateSave(p) {
        return put('/api/supplier/supplierclassify/' + p.id, p);
    },
    // 添加供应商分类
    supplierCateDelete(p) {
        return del('/api/supplier/supplierclassify/' + p.id, p);
    },
    // 添加供应商禁用
    supplierCateSet(p) {
        return put('/api/supplier/supplierclassify/set/' + p.id, p);
    },
    // 供应商仓库和分类数据源
    supplierCateSource() {
        return get('/api/supplier/source');
    },

}