import {get, post, del, put, getFile } from "../http"
export default {
    // 销售明细表
    stock_report_warming(p) {
        return get('api/report/warehouse/early', p);
    },
    stock_report_warming_excel(p) {
        return getFile('api/report/warehouse/early_excel', p);
    },
    stock_report_leave(p) {
        return get('api/report/warehouse/balance', p);
    },
    stock_report_leave_excel(p) {
        return getFile('api/report/warehouse/balance_excel', p);
    }
}