import '@/assets/css/common.scss'
import '@/assets/css/app.scss'
import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfont/20220326/iconfont.css'

import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

import '@/imports/dict'
import '@/imports/elementUI'
import '@/imports/axios'
import '@/imports/utils'
import '@/imports/app'
import '@/imports/vue2xcrud'

import '@/imports/cainiaoPrint'
import '@/imports/lodopPrint'

import VueDragResize from "vue-drag-resize"
Vue.component('vue-drag-resize', VueDragResize)


import LoadingBox from '@/components/LoadingBox'
Vue.component('LoadingBox', LoadingBox)

//easytable
import VueColumnsResizableVuetify from 'vue-columns-resizable-vuetify'
Vue.use(VueColumnsResizableVuetify)

//swiperjs
/*
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper);
*/

//第三方平台接入标识
if (location.hostname.includes('1688')) {
  Vue.prototype.$platform = '1688'
} else {
  Vue.prototype.$platform = 'other'
}

//LODOP
/*
import { getLodop } from './extends/lodop.js'
if (location.protocol == 'https:') {
    Vue.prototype.$platform = 'alibaba';
} else {
    Vue.prototype.$platform = '';
    let timer = setTimeout(() => {
        if (Vue.prototype.LODOP === undefined) {
            try {
                Vue.prototype.LODOP = getLodop();
                if (Vue.prototype.LODOP == undefined) {
                    console.log('LODOP未就绪！');
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, 4000);
}
*/

Vue.prototype.$CURRENT_ORDER_IDS = [];
Vue.prototype.$CURRENT_ORDER_LIST = [];
// 工具集
import tools from "./extends/tools";
Vue.prototype.__tools = tools;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  // 订单数据
  $CURRENT_ORDER_LIST: [],
  render: h => h(App)
}).$mount('#app')