import Layout from '@/layout'
import RouterView from '@/layout/routerView'


export default {
  path: '',
  meta: { title: '资料', icon: 'icon-ziliaoku', type: 'menu' },
  component: Layout,
  children: [
    {
      path: '',
      meta: { title: '商品', type: 'menu', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'goodsCombination', name: '商品组合',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/商品/商品组合/商品组合')
        },
        {
          path: 'goods/add_groups', name: '组合商品',
          meta: { type: 'need' },
          component: () => import('@/views/资料/商品/商品组合/组合商品/组合商品')
        },
        {
          path: 'goods/add_unit', name: '辅佐单位',
          meta: { type: 'need' },
          component: () => import('@/views/资料/商品/商品组合/辅佐单位/辅佐单位')
        },
        {
          path: 'goods/add_campaign', name: '活动创建',
          meta: { type: 'need' },
          component: () => import('@/views/资料/商品/商品组合/活动创建/活动创建')
        },
        {
          path: 'goodsBinding', name: '商品绑定',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/商品/商品绑定/商品绑定')
        },
        {
          path: 'goods', name: '商品库',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/商品/商品库/商品库')
        },
        {
          path: '/goods/add', name: '添加商品',
          meta: { type: 'need' },
          component: () => import('@/views/资料/商品/商品库/添加商品/添加商品')
        },
        {
          path: '/goods/detail', name: '商品详情',
          meta: { type: 'need' },
          component: () => import('@/views/资料/商品/商品库/商品详情/商品详情')
        },

        {
          path: 'goodsCategory', name: '商品分类',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/商品/商品分类/商品分类')
        },
        {
          path: 'goodsAttr', name: '属性库',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/商品/属性库/属性库')
        },
        {
          path: 'goodsUnit', name: '单位库',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/商品/单位库/单位库')
        },
        {
          path: 'goodsTag', name: '商品标签',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/商品/商品标签/商品标签')
        }
      ]
    },
    {
      path: '',
      meta: { title: '客户', type: 'menu', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'buyer', name: '客户管理',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/客户/客户管理/客户管理')
        },
        {
          path: 'buyerType', name: '客户分类',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/客户/客户分类/客户分类')
        },
        {
          path: 'buyerLevel', name: '客户等级',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/客户/客户等级/客户等级')
        },
        {
          path: 'buyerTag', name: '客户标签',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/客户/客户标签/客户标签')
        },
      ]
    },
    {
      path: '',
      meta: { title: '供应商', type: 'menu', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'supplier', name: '供应商管理',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/供应商/供应商管理/供应商管理')
        },
        {
          path: 'supplierType', name: '供应商分类',
          meta: { type: 'menu' },
          component: () => import('@/views/资料/供应商/供应商分类/供应商分类')
        }
      ]
    },
    {
      path: 'shop', name: '门店管理',
      meta: { type: 'menu' },
      component: () => import('@/views/资料/门店管理/门店管理')
    },
    {
      path: 'store', name: '仓库管理',
      meta: { type: 'menu' },
      component: () => import('@/views/资料/仓库管理/仓库管理')
    },
    {
      path: 'account', name: '账户管理',
      meta: { type: 'menu' },
      component: () => import('@/views/资料/账户管理/账户管理')
    }
  ]
}