import {get, post, del, put } from "../http"
export default {
    // 获取快递单打印记录
    getExpressPrintLog(p) {
        return get('api/reconciliation/print', p);
    },
    // 获取快递费用记录
    getExpressLog(p) {
        return get('api/reconciliation/express', p);
    },
    // 快递详情
    getExpressPrintDetails(p) {
        return get('api/reconciliation/express_details', p);
    },
    // 打印详情
    getExpressDetails(p) {
        return get('api/reconciliation/print_details', p);
    },

    // 资金账户
    accountList(p) {
        return get('api/accountmanagement/handle', p);
    },
    accountAdd(p) {
        return post('api/accountmanagement/handle', p);
    },
    accountEdit(p) {
        return put('api/accountmanagement/handle/' + p.id, p);
    },
    accountDetail(p) {
        return get('api/accountmanagement/handle/' + p.id, p);
    },
    accountDelete(p) {
        return del('api/accountmanagement/handle/' + p.id, p);
    },
    accountEnable(p) {
        return put('api/accountmanagement/set/' + p.id, p);
    },
    accountSource() {
        return get('api/accountmanagement/source');
    }
}