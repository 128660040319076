import {get, post, del, put, getFile } from "../http"
export default {
    // 添加采购退货申请单
    purchaseTuiAdd(p) {
        return post('api/purchase/back', p);
    },
    purchaseTuiList(p) {
        return get('api/purchase/back', p);
    },
    // 采购退货申请单详情
    purchaseTuiDetail(p) {
        return get('api/purchase/back/' + p.id, p);
    },
    // 修改采购退货申请单
    purchaseTuiSave(p) {
        return put('api/purchase/back/' + p.id, p);
    },
    // 添加采购退货申请单
    purchaseTuiDelete(p) {
        return del('api/purchase/back/' + p.id, p);
    },
    //各种数据源
    purchaseTuiSource(p) {
        return get('api/purchase/back_source', p);
    },
    // 商品弹窗
    purchaseTuiApplyGoods(p) {
        return get('api/purchase/back_goods', p);
    },
    // 导出excel
    purchaseTuiExcel(p) {
        return getFile('api/purchase/back', p);
    },
    // 审核
    purchaseTuiApply(p) {
        return post('api/purchase/back_examine', p);
    },
    form_file_upload(p) {
        return post('api/admin/file', p);
    }
}