import {get, post, del, put, getFile } from "../http"
export default {
    // 添加采购单
    paymentAdd(p) {
        return post('api/capital/payment/handle', p);
    },
    // 确认收款
    paymentConfirm(p) {
        return post('api/capital/payment/determine/' + p.id, p);
    },

    //
    paymentList(p) {
        return get('api/capital/payment/handle', p);
    },
    // 采购单详情
    paymentDetail(p) {

        return get('api/capital/payment/handle/' + p.id, p);
    },
    // 修改采购单
    paymentSave(p) {
        return put('api/capital/payment/handle/' + p.id, p);
    },
    // 添加采购单
    paymentDelete(p) {
        return del('api/capital/payment/handle/' + p.id, p);
    },
    //各种数据源
    paymentSource(p) {
        return get('api/capital/payment/source', p);
    },
    // 导出excel
    paymentExcel(p) {
        return getFile('api/capital/payment/excel', p);
    },
    //上传文件
    payment_file(p) {
        return post('api/admin/file', p);
    }
}