import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import qs from 'qs'
import { Message } from 'element-ui'

let $APIHost = ''
if (process.env.NODE_ENV == 'development') {
  $APIHost = 'http://a.dpzog.com:30002'
} else if (process.env.NODE_ENV == 'production') {
  if (location.protocol == 'https:') {
    $APIHost = 'https://a.dpzog.com'
  } else {
    $APIHost = 'http://a.dpzog.com:30002'
  }
}

const $vue2xcrudAxios = axios.create({
  paramsSerializer: params => {
    // 去除空值传参
    let nparams = {}
    for (var a in params) {
      if (params[a] !== '' && params[a] !== null) nparams[a] = params[a]
    }
    return qs.stringify(nparams, { indices: false })  // indices为false时，数组参数格式为 a: 1, a: 2，indices为true(默认)时，数组参数格式为 a[0]: 1, a[1]: 2
  }
})

$vue2xcrudAxios.interceptors.request.use(
  config => {
    config.headers['authorization-bearer'] = localStorage.getItem('authorization-bearer')
    return config
  },
)

$vue2xcrudAxios.interceptors.response.use(
  res => {
    if (res.data.code && res.data.code !== 200) {
      if (res.data.code == 10041) {
        store.commit('Logout')
        window.location.reload()
      }
      if (res.config.resMsg === true || res.config.errMsg === true) Message({ message: res.data.msg, type: 'error' })
      return Promise.reject(res.data)
    } else {
      if (res.config.resMsg === true) Message({ message: res.data.msg, type: 'success' })
      return res.config.responseType == 'blob' ? res : res.data
    }
  },
  err => {
    Message({ message: '服务异常，请联系客服！！！' + err.message, type: 'error', })
    return Promise.reject(err)
  }
)

Vue.prototype.$axios = $vue2xcrudAxios
Vue.prototype.$vue2xcrudAxios = $vue2xcrudAxios
Vue.prototype.$APIHost = $APIHost

export { $APIHost }
export default $vue2xcrudAxios
