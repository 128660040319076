import {get, post, del, put, getFile } from "../http"

export default {
    // 入库单
    allotSource(p) {
        return get('api/warehouse/allot/source', p);
    },
    allotList(p) {
        return get('api/warehouse/allot/handle', p);
    },
    allotDetail(p) {
        return get('api/warehouse/allot/handle/' + p.id, p);
    },
    allotAction(p) {
        return post('api/warehouse/allot/handle/' + p.id, p);
    },
    // 添加调拨单
    allotAdd(p) {
        return post('api/warehouse/allot/handle', p);
    },
    // 添加调拨单
    allotDelete(p) {
        return del('api/warehouse/allot/handle/' + p.id, p);
    },
    // 修改调拨单
    allotSave(p) {
        return put('api/warehouse/allot/handle/' + p.id, p);
    },
    allotExport(p) {
        return getFile('api/warehouse/allot/handle_excel' + p.id, p);
    },
    // 商品弹窗
    allotApplyGoods(p) {
        return get('api/warehouse/allot/goods', p);
    },

}