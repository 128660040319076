import {get, post, del, put, getFile } from "../http"
export default {
    // 添加采购单
    saleAdd(p) {
        return post('api/sale/apply', p);
    },
    saleList(p) {
        return get('api/sale/apply', p);
    },
    // 采购单详情
    saleDetail(p) {
        return get('api/sale/apply/' + p.id, p);
    },
    // 修改采购单
    saleSave(p) {
        return put('api/sale/apply/' + p.id, p);
    },
    // 添加采购单
    saleDelete(p) {
        return del('api/sale/apply/' + p.id, p);
    },
    //各种数据源
    saleSource(p) {
        return get('api/sale/apply_source', p);
    },
    // 商品弹窗
    saleApplyGoods(p) {
        return get('api/sale/apply_goods', p);
    },
    // 导出excel
    saleExcel(p) {
        return getFile('api/sale/apply_excel', p);
    },
    // 审核
    saleApply(p) {
        return post('api/sale/apply_examine', p);
    },
    //上传文件
    sale_file(p) {
        return post('api/admin/file', p);
    }
}