import {get, post, del, put, getFile } from "../http"
export default {
    // 热销SKU
    getHotSku(p) {
        return get('api/sales_analysis/goodssku', p);
    },
    // 销售统计
    getReportChart(p) {
        return get('api/sales_analysis/sales', p);
    },
    // 导出数据
    exportReport11(p) {
        return getFile('api/sales_analysis/sales_excel', p);
    }

}