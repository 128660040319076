<template>
  <div id="APP" class="AppFrame">
    <router-view />
    <!-- <Socket /> -->
  </div>
</template>

<script>
export default {
  components:{
    Socket: () => import('@/components/Socket')
  },
}
</script>