import {get, post, del, put, getFile } from "../http"
export default {
    // 添加收款单
    collectionAdd(p) {
        return post('api/capital/collection/handle', p);
    },
    collectionConfirm(p) {
        return post('api/capital/collection/determine/' + p.id, p);
    },
    collectionList(p) {
        return get('api/capital/collection/handle', p);
    },
    // 收款单详情
    collectionDetail(p) {
        return get('api/capital/collection/handle/' + p.id, p);
    },
    // 修改收款单
    collectionSave(p) {
        return put('api/capital/collection/handle/' + p.id, p);
    },
    // 添加收款单
    collectionDelete(p) {
        return del('api/capital/collection/handle/' + p.id, p);
    },
    //各种数据源
    collectionSource(p) {
        return get('api/capital/collection/source', p);
    },
    // 导出excel
    collectionExcel(p) {
        return getFile('api/capital/collection/excel', p);
    },
    //上传文件
    collection_file(p) {
        return post('api/admin/file', p);
    }
}