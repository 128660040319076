import Layout from '@/layout'
import RouterView from '@/layout/routerView'


export default {
  path: '',
  meta: { roles: [3], title: '销售', icon: 'icon-xiaoshou', type: 'menu' },
  component: Layout,
  children: [
    {
      path: 'sales', name: '销售单',
      meta: { type: 'menu' },
      component: () => import('@/views/销售/销售单/销售单')
    },
    {
      path: 'sale/add', name: '销售单新增',
      meta: { type: 'need' },
      component: () => import('@/views/销售/销售单/销售单新增/销售单新增')
    },
    {
      path: 'sale/detail', name: '销售单详情',
      meta: { type: 'need' },
      component: () => import('@/views/销售/销售单/销售单详情/销售单详情')
    },

    {
      path: 'salesReturn', name: '销售退货单',
      meta: { type: 'menu' },
      component: () => import('@/views/销售/销售退货单/销售退货单')
    },
    {
      path: 'sale_tui/add', name: '销售退货单新增',
      meta: { type: 'need' },
      component: () => import('@/views/销售/销售退货单/销售退货单新增/销售退货单新增')
    },
    {
      path: 'sale_tui/detail', name: '销售退货单详情',
      meta: { type: 'need' },
      component: () => import('@/views/销售/销售退货单/销售退货单详情/销售退货单详情')
    },

  ]
}



