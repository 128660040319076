import {get, post, del, put, getFile } from "../http"
export default {
    // 添加采购单
    sale_tuiAdd(p) {
        return post('api/sale/back', p);
    },
    sale_tuiList(p) {
        return get('api/sale/back', p);
    },
    // 采购单详情
    sale_tuiDetail(p) {
        return get('api/sale/back/' + p.id, p);
    },
    // 修改采购单
    sale_tuiSave(p) {
        return put('api/sale/back/' + p.id, p);
    },
    // 添加采购单
    sale_tuiDelete(p) {
        return del('api/sale/back/' + p.id, p);
    },
    //各种数据源
    sale_tuiSource(p) {
        return get('api/sale/back_source', p);
    },
    // 商品弹窗
    sale_tuiApplyGoods(p) {
        return get('api/sale/back_goods', p);
    },
    // 导出excel
    sale_tuiExcel(p) {
        return getFile('api/sale/back_excel', p);
    },
    // 审核
    sale_tuiApply(p) {
        return post('api/sale/back_examine', p);
    },
    //上传文件
    sale_tui_file(p) {
        return post('api/admin/file', p);
    }
}