import Layout from '@/layout'

export default [
  {
    path: '',
    meta: { title: '打印发货', icon: 'icon-layer-copy', type: 'menu' },
    component: Layout,
    children: [
      {
        path: 'recentOrder', name: '近三个月订单',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/打印发货/近三个月订单')
      },
      {
        path: 'paddingOrder', name: '待发货订单',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/打印发货/待发货订单/待发货订单')
      },
      {
        path: 'shippedOrder', name: '已发货订单',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/打印发货/已发货订单')
      },
      {
        path: 'historyOrder', name: '历史订单',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/打印发货/历史订单')
      }
    ]
  },
  {
    path: '',
    meta: { title: '扫描发货', icon: 'icon-scan', type: 'menu' },
    component: Layout,
    children: [
      {
        path: 'scanPrint', name: '扫描打印',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/扫描发货/扫描打印/扫描打印')
      }
    ]
  },
  {
    path: '',
    meta: { title: '退款售后', icon: 'icon-tuikuan', type: 'menu' },
    component: Layout,
    children: [
      {
        path: 'refundingOrder', name: '退款中的订单',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/退款售后/退款中的订单/退款中的订单')
      },
      {
        path: 'afterSalesOrder', name: '全部售后订单',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/退款售后/全部售后订单/全部售后订单')
      }
    ]
  },
  {
    path: '',
    meta: { title: '会员管理', icon: 'icon-huiyuan-', type: 'menu' },
    component: Layout,
    children: [
      {
        path: 'memberLevel', name: '等级列表',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/会员管理/等级列表/等级列表')
      },
      {
        path: 'memberScore', name: '积分规则',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/会员管理/积分规则/积分规则')
      },
      {
        path: 'memberCenter', name: '会员中心',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/会员管理/会员中心/会员中心')
      }
    ]
  },
  {
    path: '',
    meta: { title: '销售分析', icon: 'icon-fenxi', type: 'menu' },
    component: Layout,
    children: [
      {
        path: 'salesReport', name: '销售报表',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/销售分析/销售报表/销售报表')
      },
      {
        path: 'hotSKU', name: '热销SKU',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/销售分析/热销SKU/热销SKU')
      }
    ]
  },
  {
    path: '',
    meta: { title: '日志对账', icon: 'icon-rizhi_huaban1', type: 'menu' },
    component: Layout,
    children: [
      {
        path: 'printLog', name: '打印日志',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/日志对账/打印日志/打印日志')
      },
      {
        path: 'expressAccount', name: '快递对账',
        meta: { type: 'menu' },
        component: () => import('@/views/电商管理/日志对账/快递对账/快递对账')
      }
    ]
  },
  {
    path: '',
    meta: { title: '设置', icon: 'icon-shezhi1', type: 'menu' },
    component: Layout,
    children: [
      {
        path: 'printSetting', name: '打印设置',
        meta: { type: 'menu' },
        component: () => import('@/views/设置/打印设置/打印设置')
      },
      {
        path: 'printDesign', name: '打印模板设计',
        meta: { type: 'menu' },
        component: () => import('@/views/设置/打印模板设计/打印模板设计')
      },
      {
        path: 'editor', name: '模板编辑',
        meta: { type: 'need' },
        component: () => import('@/views/设置/打印模板设计/模板编辑/editor')
      },
      {
        path: 'cainiao/lists', name: '菜鸟模板',
        meta: { type: 'need' },
        component: () => import('@/views/设置/打印模板设计/菜鸟模板/菜鸟模板')
      },
      {
        path: 'bindingStore', name: '多店铺绑定',
        meta: { type: 'menu' },
        component: () => import('@/views/设置/多店铺绑定/多店铺绑定')
      },
      {
        path: 'staffPermission', name: '员工及权限管理',
        meta: { type: 'menu' },
        component: () => import('@/views/设置/员工及权限管理/员工及权限管理')
      },
    ]
  }
]