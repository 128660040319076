import {get, post, del, put } from "../http"

export default {
    // 添加客户
    customerAdd(p) {
        return post('/api/customer/handle', p);
    },
    customerList(p) {
        return get('/api/customer/handle', p);
    },
    // 客户详情
    customerDetail(p) {
        return get('/api/customer/handle/' + p.id, p);
    },
    // 修改客户
    customerSave(p) {
        return put('/api/customer/handle/' + p.id, p);
    },
    // 禁用客户
    customerSet(p) {
        return put('api/customer/set/' + p.id, p);
    },
    // 添加客户
    customerDelete(p) {
        return del('/api/customer/handle/' + p.id, p);
    },
    // 各种数据源
    getCustomrSource() {
        return get('/api/customer/source');
    },

    // 分类
    // 添加客户分类
    customerCateAdd(p) {
        return post('/api/customer/customerclassify', p);
    },
    customerCateList(p) {
        return get('/api/customer/customerclassify', p);
    },
    // 客户分类详情
    customerCateDetail(p) {
        return get('/api/customer/customerclassify/' + p.id, p);
    },
    // 修改客户分类
    customerCateSave(p) {
        return put('/api/customer/customerclassify/' + p.id, p);
    },
    // 禁用客户分类
    customerCateSet(p) {
        return put('api/customer/customerclassify/set/' + p.id, p);
    },
    // 添加客户分类
    customerCateDelete(p) {
        return del('/api/customer/customerclassify/' + p.id, p);
    },
    // 标签
    // 添加客户标签
    customerLabelAdd(p) {
        return post('/api/customer/customerlabel', p);
    },
    customerLabelList(p) {
        return get('/api/customer/customerlabel', p);
    },
    // 客户标签详情
    customerLabelDetail(p) {
        return get('/api/customer/customerlabel/' + p.id, p);
    },
    // 修改客户标签
    customerLabelSave(p) {
        return put('/api/customer/customerlabel/' + p.id, p);
    },
    // 禁用客户标签
    customerLabelSet(p) {
        return put('api/customer/customerlabel/set/' + p.id, p);
    },
    // 添加客户标签
    customerLabelDelete(p) {
        return del('/api/customer/customerlabel/' + p.id, p);
    },
    // 等级
    // 添加客户等级
    customerGradeAdd(p) {
        return post('/api/customer/customergrade', p);
    },
    customerGradeList(p) {
        return get('/api/customer/customergrade', p);
    },
    // 客户等级详情
    customerGradeDetail(p) {
        return get('/api/customer/customergrade/' + p.id, p);
    },
    // 修改客户等级
    customerGradeSave(p) {
        return put('/api/customer/customergrade/' + p.id, p);
    },
    // 禁用客户等级
    customerGradeSet(p) {
        return put('api/customer/customergrade/set/' + p.id, p);
    },
    // 添加客户等级
    customerGradeDelete(p) {
        return del('/api/customer/customergrade/' + p.id, p);
    }

}