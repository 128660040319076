import {get, post, del, put } from "../http"

export default {
    // 订单列表
    strategyList(p) {
        return get('api/strategy/merge_order', p);
    },
    // 订单列表
    strategySave(p) {
        return post('api/strategy/merge_order', p);
    }
}