import {get, post, del, put } from "../http"

export default {
    // 添加仓库
    storeAdd(p) {
        return post('/api/warehouse/handle', p);
    },
    storeList(p) {
        return get('/api/warehouse/handle', p);
    },
    // 仓库详情
    storeDetail(p) {
        return get('/api/warehouse/handle/' + p.id, p);
    },
    // 修改仓库
    storeSave(p) {
        return put('/api/warehouse/handle/' + p.id, p);
    },
    // 禁用仓库
    storeEnable(p) {
        return put('api/warehouse/set/' + p.id, p);
    },
    // 添加仓库
    storeDelete(p) {
        return del('/api/warehouse/handle/' + p.id, p);
    }

}