import Layout from '@/layout'
import RouterView from '@/layout/routerView'

export default {
  path: '',
  meta: { title: '设置', icon: 'icon-shezhi1', type: 'menu' },
  component: Layout,
  children: [
    {
      path: 'printSetting', name: '打印设置',
      meta: { type: 'menu' },
      component: () => import('@/views/设置/打印设置/打印设置')
    },
    {
      path: 'printDesign', name: '打印模板设计',
      meta: { type: 'menu' },
      component: () => import('@/views/设置/打印模板设计/打印模板设计')
    },
    {
      path: 'editor', name: '模板编辑',
      meta: { type: 'need' },
      component: () => import('@/views/设置/打印模板设计/模板编辑/editor')
    },
    {
      path: 'cainiao/lists', name: '菜鸟模板',
      meta: { type: 'need' },
      component: () => import('@/views/设置/打印模板设计/菜鸟模板/菜鸟模板')
    },


    {
      path: 'bindingStore', name: '多店铺绑定',
      meta: { type: 'menu' },
      component: () => import('@/views/设置/多店铺绑定/多店铺绑定')
    },
    {
      path: 'staffPermission', name: '员工及权限管理',
      meta: { type: 'menu' },
      component: () => import('@/views/设置/员工及权限管理/员工及权限管理')
    },
    {
      path: 'operationLog', name: '操作日志',
      meta: { type: 'menu' },
      component: () => import('@/views/设置/操作日志')
    },
    {
      path: 'changePassword', name: '修改密码',
      meta: { type: 'menu' },
      component: () => import('@/views/设置/修改密码')
    }
  ]
}
