import Layout from '@/layout'
import RouterView from '@/layout/routerView'

export default {
  path: '',
  meta: { title: '报表', icon: 'icon-wxbbaobiao', type: 'menu' },
  component: Layout,
  children: [
    {
      path: '',
      meta: { title: '销售报表', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'salesReport', name: '销售明细表',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/销售报表/销售明细表')
        },
        {
          path: 'salesCategoryReport', name: '销售汇总表(按类别)',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/销售报表/销售汇总表(按类别)')
        },
        {
          path: 'salesGoodsReport', name: '销售汇总表(按商品)',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/销售报表/销售汇总表(按商品)')
        },
        {
          path: 'salesBuyerReport', name: '销售汇总表(按客户)',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/销售报表/销售汇总表(按客户)')
        },
        {
          path: 'salesProfitReport', name: '销售利润表',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/销售报表/销售利润表')
        }
      ]
    },
    {
      path: '',
      meta: { title: '采购报表', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'purchaseReport', name: '采购明细表',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/采购报表/采购明细表')
        },
        {
          path: 'purchaseCategoryReport', name: '采购汇总表(按类别)',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/采购报表/采购汇总表(按类别)')
        },
        {
          path: 'purchaseGoodsReport', name: '采购汇总表(按商品)',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/采购报表/采购汇总表(按商品)')
        },
        {
          path: 'purchaseSupplierReport', name: '采购汇总表(按供应商)',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/采购报表/采购汇总表(按供应商)')
        }
      ]
    },
    {
      path: '',
      meta: { title: '库存报表', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'stockAlert', name: '商品库存预警表',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/库存报表/商品库存预警表')
        },
        {
          path: 'stockRemain', name: '商品库存余额表',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/库存报表/商品库存余额表')
        }
      ]
    },
    {
      path: '',
      meta: { title: '财务报表', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'buyerCheck', name: '客户对账',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/财务报表/客户对账')
        },
        {
          path: 'supplierCheck', name: '供应商对账单',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/财务报表/供应商对账单')
        }
      ]
    },
    {
      path: '',
      meta: { title: '数据分析', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'overview', name: '数据概况',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/数据分析/数据概况')
        },
        {
          path: 'salesOverview', name: '销售业绩',
          meta: { type: 'menu' },
          component: () => import('@/views/报表/数据分析/销售业绩')
        }
      ]
    }
  ]
}
