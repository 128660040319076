import {get, post, del, put, getFile } from "../http"

export default {
    // 入库单
    inventorySource(p) {
        return get('api/warehouse/inventory/source', p);
    },
    inventoryList(p) {
        return get('api/warehouse/inventory/handle', p);
    },
    inventoryDetail(p) {
        return get('api/warehouse/inventory/handle/' + p.id, p);
    },
    inventoryAction(p) {
        return post('api/warehouse/inventory/handle/' + p.id, p);
    },
    // 添加采购单
    inventoryAdd(p) {
        return post('api/warehouse/inventory/handle', p);
    },
    // 添加采购单
    inventoryDelete(p) {
        return del('api/warehouse/inventory/handle/' + p.id, p);
    },
    // 修改采购单
    inventorySave(p) {
        return put('api/warehouse/inventory/handle/' + p.id, p);
    },
    inventoryExport(p) {
        return getFile('api/warehouse/inventory/handle_excel' + p.id, p);
    },
    // 商品弹窗
    inventoryApplyGoods(p) {
        return get('api/warehouse/inventory/goods', p);
    },

}