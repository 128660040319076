import {get, post, del, put, getFile } from "../http"

export default {
    // 同意退款
    agreeRefundMoney(p) {
        return post('api/commerce/orders_refund_button/refundsagree', p);
    },

    // 同意退货
    agreeRefundGoods(p) {
        return post('api/commerce/orders_refund_button/returngoodsagree', p);
    },
    // 拒绝退货
    unAgreeRefundGoods(p) {
        return post('api/commerce/orders_refund_button/returngoodsrefuse', p);
    },
    // 确认收货
    confirmReceiveGoods(p) {
        return post('api/commerce/orders_refund_button/confirmreceipt', p);
    },

    // 订单列表
    orderList(p, url) {
        return get(url, p);
    },

    // 检查是否绑定仓库
    checkWareHouseBind(p) {
        return post('api/commerce/orders_logistics_send_before', p);
    },
    // 获取仓库数据源
    getWareHouseSource() {
        return get('api/commerce/area_warehouse_source');
    },
    // 获取仓库数据源
    getWareHouseBinding() {
        return get('api/commerce/area_warehouse_index');
    },
    // 获取地区信息
    getAreaTree() {
        return get('api/area/china', { structure: 'pc' });
    },
    // 更新
    addBind(p) {
        return post('api/commerce/area_warehouse', p);
    },
    // 新增
    updateBind(p) {
        return put('api/commerce/area_warehouse/' + p.id, p);
    },

    // 订单详情
    orderDetail(p) {
        return get('api/commerce/order/' + p.id);
    },
    //获取订单字段信息
    fieldList(p) {
        return get('api/commerce/orders_get_field', p);
    },
    // 保存订单字段信息
    saveField(p) {
        return post('api/commerce/orders_update_field', p);
    },
    //修改卖家备注
    saveSellerRemark(p) {
        return post('api/commerce/orders_update', p);
    },
    //合并订单
    mergeOrder(p) {
        return post('api/commerce/orders_merge_order', p);
    },
    //拆分订单
    splitOrder(p) {
        return post('api/commerce/orders_split_confirm', p);
    },
    // 通过多个id获取多个订单详情
    getOrderByIds(p) {
        return get('api/commerce/ordersinfo', p);
    },
    // 获取备货单聚合数据
    getPrepareOrder(p) {
        return get('api/commerce/orders_prepare_goods', p);
    },
    // 根据单据类型获取动态字段
    getDynamicField(p) {
        return get('api/print/type_field', p);
    },
    // 动态获取省市县信息
    getAreaInfo(p) {
        return get('api/area/china', p);
    },
    //保存收货地址
    saveReceiverAddress(p) {
        return post('api/commerce/updateaddress', p);
    },
    // 保存发货单打印状态
    saveOrderSendTplStatus(p) {
        return post('api/commerce/orders_send', p);
    },
    // 订单发货
    saveOrderSendStatus(p) {
        return post('api/commerce/orders_logistics_send', p);
    },
    // 保存备货单打印状态
    saveOrderPrepareStatus(p) {
        return post('api/commerce/orders_prepare_goods', p);
    },
    // 保存快递单信息
    saveOrderExpressStatus(p) {
        return post('api/commerce/orders_express', p);
    },
    // 保存条件单
    saveReq(p) {
        return post('api/commerce/search', p);
    },
    getReqList(p) {
        return get('api/commerce/search', p);
    },
    deleteReq(p) {
        return del('api/commerce/search/' + p.id, p);
    },
    exportOrder(p) {
        return getFile('api/commerce/orders_excel', p);
    }


}