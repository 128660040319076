import Vue from 'vue'

const $dict = {
  状态: [
    { label: '启用', value: 1 },
    { label: '禁用', value: 2 }
  ],
  有无: [
    { label: '有', value: 1 },
    { label: '无', value: 0 }
  ],
  是否: [
    { label: '是', value: 1 },
    { label: '否', value: 0 }
  ],
  是否打印: [
    { label: '已打印', value: 1 },
    { label: '未打印', value: 0 }
  ],
  是否备货: [
    { label: '已备货', value: 2 },
    { label: '未备货', value: 3 }
  ],
  旗帜颜色: [
    { label: '红', value: '红', value2: 1, color: 'red' },
    { label: '黄', value: '黄', value2: 2, color: 'orange' },
    { label: '绿', value: '绿', value2: 3, color: 'green' },
    { label: '蓝', value: '蓝', value2: 4, color: 'blue' },
    { label: '紫', value: '紫', value2: 5, color: 'purple' },
    { label: '未标记', value: '未标记', value2: 0, color: 'none' }
  ],
  订单状态: [
    { label: '全部', value: '' },
    { label: '等待买家付款', value: 'WAIT_BUYER_PAY' },
    { label: '等待卖家发货', value: 'WAIT_SELLER_SEND_GOODS' },
    { label: '卖家部分发货', value: 'SELLER_CONSIGNED_PART' },
    { label: '等待买家确认收货', value: 'WAIT_BUYER_CONFIRM_GOODS' },
    { label: '买家已签收（货到付款专用）', value: 'TRADE_BUYER_SIGNED' },
    { label: '交易成功', value: 'TRADE_FINISHED' },
    { label: '交易关闭', value: 'TRADE_CLOSED' },
    { label: '交易被淘宝关闭', value: 'TRADE_CLOSED_BY_TAOBAO' },
    { label: '没有创建外部交易（支付宝交易）', value: 'TRADE_NO_CREATE_PAY' }, // 原版没有
    { label: '余额宝0元购合约中', value: 'WAIT_PRE_AUTH_CONFIRM' },
    { label: '外卡支付付款确认中', value: 'PAY_PENDING' },
    { label: '所有买家未付款的交易', value: 'ALL_WAIT_PAY' }, // 原版没有
    { label: '所有关闭的交易', value: 'ALL_CLOSED' },
    { label: '已付款但是处于禁止发货状态', value: 'PAID_FORBID_CONSIGN' }
  ],
  退款状态: [
    { label: '全部', value: '' },
    { label: '买家已经申请退款，等待卖家同意', value: 'WAIT_SELLER_AGREE' },
    { label: '卖家已经同意退款，等待买家退货', value: 'WAIT_BUYER_RETURN_GOODS' },
    { label: '买家已经退货，等待卖家确认收货', value: 'WAIT_SELLER_CONFIRM_GOODS' },
    { label: '卖家拒绝退款', value: 'SELLER_REFUSE_BUYER' },
    { label: '退款关闭', value: 'CLOSED' }, // 原版没有
    { label: '退款成功', value: 'SUCCESS' },
    { label: '没有退货', value: 'NO_REFUND' },
  ],
  订单表格列宽: [
    { label: '特殊备注', value: 100 },
    { label: '卖家备注旗帜', value: 120 },
    { label: '订单ID(电商)', value: 180 },
    { label: '店铺名称', value: 120 },
    { label: '买家昵称', value: 120 },
    { label: '实付金额', value: 100 },
    { label: '交易状态', value: 100 },
    { label: '收货人的手机号码', value: 150 },
    { label: '收货人的姓名', value: 120 },
    { label: '收货人的所在城市', value: 150 },
    { label: '付款时间', value: '时间' },

    { label: '订单ID(ERP)', value: 150 },
    { label: '订单编号', value: 150 },
    { label: '卖家手工调整金额', value: 140 },
    { label: '系统优惠金额', value: 120 },
    { label: '收货人的电话号码', value: 140 },
    { label: '收货人的所在省份', value: 140 },
    { label: '收货人的所在地区', value: 140 },
    { label: '收货人街道地址', value: 140 },
    { label: '收货人的详细地址', value: 300 },
    { label: '收货人的邮编', value: 120 },
    { label: '卖家发货时间', value: '时间' },
    { label: '交易创建时间', value: '时间' },
    { label: '买家备注', value: '内容' },
    { label: '卖家备注', value: '内容' },
  ],
}

Vue.prototype.$dict = $dict

export default $dict