import Layout from '@/layout'

export default [
  {
    path: '',
    meta: { title: '模版管理', icon: 'icon-zhizhang', type: 'menu' },
    component: Layout,
    children: [
      {
        path: 'printDesign', name: '模版列表',
        meta: { type: 'menu' },
        component: () => import('@/views/设置/打印模板设计/打印模板设计')
      },
      {
        path: 'editor', name: '模板编辑',
        meta: { type: 'need' },
        component: () => import('@/views/设置/打印模板设计/模板编辑/editor')
      },
      {
        path: 'cainiao/lists', name: '菜鸟模板',
        meta: { type: 'need' },
        component: () => import('@/views/设置/打印模板设计/菜鸟模板/菜鸟模板')
      },
    ]
  }
]