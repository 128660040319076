import {get, post, del, put, getFile } from "../http"
export default {
    // 销售明细表
    purchase_report_index(p) {
        return get('api/report/purchase/index', p);
    },
    purchase_report_index_excel(p) {
        return getFile('api/report/purchase/index_excel', p);
    },
    // 销售汇总表(按类别)
    purchase_report_cate(p) {
        return get('api/report/purchase/class', p);
    },
    // 销售汇总表(按类别)
    purchase_report_cate_excel(p) {
        return getFile('api/report/purchase/class_excel', p);
    },
    // 销售汇总表(按类别)
    purchase_report_goods(p) {
        return get('api/report/purchase/goods', p);
    },
    // 销售汇总表(按类别)
    purchase_report_goods_excel(p) {
        return getFile('api/report/purchase/goods_excel', p);
    },

    // 销售汇总表(按客户)
    purchase_report_custom(p) {
        return get('api/report/purchase/supplier', p);
    },
    // 销售汇总表(按类别)
    purchase_report_custom_excel(p) {
        return getFile('api/report/purchase/supplier_excel', p);
    }
}