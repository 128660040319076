import Layout from '@/layout'
import RouterView from '@/layout/routerView'


export default {
  path: '',
  meta: { title: '电商管理', icon: 'icon-layer-copy', type: 'menu' },
  component: Layout,
  children: [
    {
      path: 'testPrint', name: '打印测试',
      meta: { type: 'need' },
      component: () => import('@/views/电商管理/打印测试')
    },
    {
      path: 'stockOrder', name: '备货管理',
      meta: { type: 'menu' },
      component: () => import('@/views/电商管理/备货管理/备货管理')
    },
    {
      path: 'stockOrder/detail/:id', name: '备货管理详情',
      meta: { type: 'need' },
      component: () => import('@/views/电商管理/备货管理/备货管理-详情')
    },

    {
      path: '',
      meta: { title: '打印发货', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'recentOrder', name: '近三个月订单',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/打印发货/近三个月订单')
        },
        {
          path: 'paddingOrder', name: '待发货订单',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/打印发货/待发货订单/待发货订单')
        },
        {
          path: 'shippedOrder', name: '已发货订单',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/打印发货/已发货订单')
        },
        {
          path: 'historyOrder', name: '历史订单',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/打印发货/历史订单')
        }
      ]
    },
    {
      path: '',
      meta: { title: '扫描发货', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'scanPrint', name: '扫描打印',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/扫描发货/扫描打印/扫描打印')
        }
      ]
    },
    {
      path: '',
      meta: { title: '退款售后', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'refundOrder', name: '退款列表',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/退款售后/退款列表/退款列表')
        },
        {
          path: 'returnOrder', name: '退货列表',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/退款售后/退货列表/退货列表')
        },
        {
          path: 'refundingOrder', name: '退款中的订单',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/退款售后/退款中的订单/退款中的订单')
        },
        {
          path: 'afterSalesOrder', name: '全部售后订单',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/退款售后/全部售后订单/全部售后订单')
        }
      ]
    },
    {
      path: '',
      meta: { title: '会员管理', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'memberLevel', name: '等级列表',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/会员管理/等级列表/等级列表')
        },
        {
          path: 'memberScore', name: '积分规则',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/会员管理/积分规则/积分规则')
        },
        {
          path: 'memberCenter', name: '会员中心',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/会员管理/会员中心/会员中心')
        }
      ]
    },
    {
      path: '',
      meta: { title: '销售分析', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'salesReport', name: '销售报表',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/销售分析/销售报表/销售报表')
        },
        {
          path: 'hotSKU', name: '热销SKU',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/销售分析/热销SKU/热销SKU')
        }
      ]
    },
    {
      path: '',
      meta: { title: '日志对账', type: 'menu' },
      component: RouterView,
      children: [
        {
          path: 'printLog', name: '打印日志',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/日志对账/打印日志/打印日志')
        },
        {
          path: 'expressAccount', name: '快递对账',
          meta: { type: 'menu' },
          component: () => import('@/views/电商管理/日志对账/快递对账/快递对账')
        }
      ]
    }
  ]
}
