export default {
  data () {
    return {
      DataInitLoading: false,
      Inits: {},
      Forms: {}
    }
  },
  created () {
    for (let k in this.Inits) {
      if (!this._isType(this.Inits[k], 'Object')) continue
      this._dataInit(k)
    }
    for (let k in this.Forms) {
      if (typeof this.Forms[k]['loading'] === 'undefined') this.$set(this.Forms[k], 'loading', false)
      if (typeof this.Forms[k]['data'] === 'undefined') this.$set(this.Forms[k], 'data', {})
    }
  },
  methods: {
    _dataInit (item) {
      if (typeof this[item] === 'undefined') this[item] = Object.assign({})
      if (!this._isType(this.Inits[item], 'Object')) this.$set(this.Inits, item, {})
      if (typeof this.Inits[item]['loading'] === 'undefined') this.$set(this.Inits[item], 'loading', false)
      // 不能强制设定，可以用作业务判断
      if (typeof this.Inits[item]['disabled'] === 'undefined') this.$set(this.Inits[item], 'disabled', false)
      // if (typeof this.Inits[item]['data'] === 'undefined') this.$set(this.Inits[item], 'data', {})
      // if (typeof this.Inits[item]['reqOptions'] === 'undefined') this.$set(this.Inits[item], 'reqOptions', {})
      // if (typeof this.Inits[item]['formConfig'] === 'undefined') this.$set(this.Inits[item], 'formConfig', {})
    },
    // 数据初始化 -- 不能异步等待，否则阻塞所有
    async DataInit (options = {}) {
      let inits = {}
      if (options.initName) {
        if (this._isType(options.initName, 'Array')) {
          options.initName.forEach(item => {
            inits[item] = this.Inits[item] || {}
          })
        } else {
          inits[options.initName] = this.Inits[options.initName] || {}
        }
      } else {
        inits = this.Inits
      }

      this.DataInitLoading = true
      let initCount = 0
      for (let k in inits) {
        this._dataInit(k)
        if (options.disabled || this.Inits[k]['disabled']) continue

        this.Inits[k]['loading'] = true

        initCount ++ // 统计异步请求数
        if (typeof this.DataInitCount !== 'undefined') this.DataInitCount ++

        let initItem = { reqOptions: {} } // 不能使用options, 调用DataInit时会是同一options

        initItem.data = (this._isType(this.Inits[k]['data'], 'Object') && this._isType(options.data, 'Object')) ? Object.assign(this.Inits[k]['data'], options.data) : (options.data || this.Inits[k]['data'])

        let reqOptionsParams = (options.reqOptions && options.reqOptions.params) || (this.Inits[k]['reqOptions'] && this.Inits[k]['reqOptions']['params'])
        if (this.Inits[k]['reqOptions'] && this._isType(this.Inits[k]['reqOptions']['params'], 'Object') && options.reqOptions && this._isType(options.reqOptions.params, 'Object')) {
          initItem.reqOptions.params = Object.assign(this.Inits[k]['reqOptions']['params'], options.reqOptions.params)
        } else if (reqOptionsParams) {
          initItem.reqOptions.params = reqOptionsParams
        }

        let reqOptionsData = (options.reqOptions && options.reqOptions.data) || (this.Inits[k]['reqOptions'] && this.Inits[k]['reqOptions']['data'])
        if (this.Inits[k]['reqOptions'] && this._isType(this.Inits[k]['reqOptions']['data'], 'Object') && options.reqOptions && this._isType(options.reqOptions.data, 'Object')) {
          initItem.reqOptions.data = Object.assign(this.Inits[k]['reqOptions']['data'], options.reqOptions.data)
        } else if (reqOptionsData) {
          initItem.reqOptions.data = reqOptionsData
        }

        if (this._isType(this.Inits[k]['reqOptions'], 'Object') && this._isType(options.reqOptions, 'Object')) {
          let reqOptions = {}
          for (var a in options.reqOptions) if (!['params', 'data'].includes(a)) reqOptions[a] = options.reqOptions[a]
          initItem.reqOptions = Object.assign(this.Inits[k]['reqOptions'], reqOptions)
        } else {
          initItem.reqOptions = options.reqOptions || this.Inits[k]['reqOptions']
        }

        initItem.formConfig = (this._isType(this.Inits[k]['formConfig'], 'Object') && this._isType(options.formConfig, 'Object')) ? Object.assign(this.Inits[k]['formConfig'], options.formConfig) : (options.formConfig || this.Inits[k]['formConfig'])

        if (this._isType(this['Set' + k], 'Function')) {
          try {
            if (await this['Set' + k].call(this.Inits[k], initItem) == false) {
              initCount --
              if (typeof this.DataInitCount !== 'undefined') this.DataInitCount --
              this._DataInitDone(options, k, initCount)
              continue
            }
          } catch(err) {
            console.error(err)
            this.$message.error(`请检查 Set${k} 回调方法中逻辑或语法错误！`)
            initCount --
            if (typeof this.DataInitCount !== 'undefined') this.DataInitCount --
            this._DataInitDone(options, k, initCount)
            continue
          }
        }

        // 处理当 this.Inits[k] 不是对象时 initItem.reqOptions 会重新赋值 undefined
        if (initItem.reqOptions && initItem.reqOptions.url) {
          // 目前只支持异步
          this.$vue2xcrudAxios(initItem.reqOptions).then((res) => {
            initItem.res = res

            let resData = options.resData || this.Inits[k]['resData']
            let resData2 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataDetail && this.$vue2xcrudConfig.dataDetail.resData
            let resData3 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.resDataKey
            if (typeof resData === 'undefined' && typeof resData2 === 'undefined' && typeof resData3 === 'undefined') {
              console.warn(`请在 vue2xcrudConfig 中设定 resDataKey 或在 vue2xcrudConfig.dataDetail 中设定 resData 或在 Inits.${k} 中设定 resData`)
            }
            let result = (this._isType(resData, 'Function') ? resData.call(this.Inits[k], res) :
              this._isType(resData2, 'Function') ? resData2(res) :
                this._isType(resData3, 'Function') ? resData3(res) :
                  res[resData3])

            // 以当前 Inits data 合并覆盖 vue data 对象中的值
            initItem.data = (this._isType(this[k], 'Object') && this._isType(initItem.data, 'Object')) ? Object.assign(this[k], initItem.data) : initItem.data
            // 以请求响应值继续合并覆盖
            initItem.data = (this._isType(result, 'Object') && this._isType(initItem.data, 'Object')) ? Object.assign(initItem.data, result) : result

            this._DataInitDatas(options, k, initItem)
            initCount --
            if (typeof this.DataInitCount !== 'undefined') this.DataInitCount --
            this._DataInitDone(options, k, initCount)
          }).catch(err => {
            initItem.err = err
            initCount --
            if (typeof this.DataInitCount !== 'undefined') this.DataInitCount --
            this._DataInitDone(options, k, initCount)
          })
        } else {
          initItem.data = (this._isType(this[k], 'Object') && this._isType(initItem.data, 'Object')) ? Object.assign(this[k], initItem.data) : initItem.data

          this._DataInitDatas(options, k, initItem)
          initCount --
          if (typeof this.DataInitCount !== 'undefined') this.DataInitCount --
          this._DataInitDone(options, k, initCount)
        }
      }
      // 处理当所有 Inits 被 disabled 后 initCount 初始为 0
      this._DataInitDone(options, null, initCount)
    },
    _DataInitDatas (options, k, initItem) {
      if (this._isType(this[k + 'Set'], 'Function')) {
        // 接收 XXXSet 的返回值是为了转换 initItem.data 的数据类型，若 initItem.data 为对象时 XXXSet 无需 return 直接修改 initItem.data 对象即可
        //let result = this[k + 'Set'].call(this[k], initItem)
        //if (result) initItem.data = result
        this[k + 'Set'].call(this[k], initItem)
      }

      if (this._isType(initItem.data, 'Object')) {
        // 初始化 Form.data
        for (var a in initItem.formConfig) {
          if (!this._isType(this['Forms'][a]['data'], 'Object')) break

          if (this._isType(initItem.formConfig[a], 'Array')) {
            initItem.formConfig[a].map((item) => {
              this.$set(this['Forms'][a]['data'], item, initItem['data'][item])
              // this['Forms'][a]['data'][item] = initItem['data'][item]
            })
          } else if (initItem.formConfig[a] === 'all') {
            // 2022.02.02 新增注册处理
            for (var b in initItem['data']) {
              this.$set(this['Forms'][a]['data'], b, initItem['data'][b])
            }
            // Object.assign(this['Forms'][a]['data'], initItem['data'])
          }
        }
      } else if (initItem.data) {
        // 初始化 Form.data
        for (var c in initItem.formConfig) {
          if (initItem.formConfig[c] === 'all') {
            // 当 initItem.data 为字符串时直接覆盖
            this['Forms'][c]['data'] = initItem.data
          }
        }
      }

      //this[k] = initItem['data']  // 在某些情况会报类型转换错误
      if (this._isType(this[k], 'Object') && this._isType(initItem.data, 'Object')) {
        // 数组不能用 Object.assign
        Object.assign(this[k], initItem.data)
      } else if (initItem.data) {
        this[k] = initItem.data
      }

      if (this.$vue2xcrudConfig && this.$vue2xcrudConfig.console == true) console.log('DataInit >> ', this[k], this['Forms'], ' <<')
    },
    _DataInitDone (options, k, initCount) {
      if (k) {
        this.Inits[k]['loading'] = false
        this._isType(this[k + 'InitDone'], 'Function') && this[k + 'InitDone'].call(this, options)
      }

      if (typeof this.DataInitCount !== 'undefined') {
        if (this.DataInitCount == 0) {
          this.DataInitLoading = false
          this._isType(this['DataInitDone'], 'Function') && this['DataInitDone']()
        }
      } else if (initCount == 0) {
        this.DataInitLoading = false
        this._isType(this['DataInitDone'], 'Function') && this['DataInitDone']()
      }
    },

    // 表单提交
    async FormSubmit (options = {}) {
      if (this.$vue2xcrudConfig && this.$vue2xcrudConfig.console == true) console.log('FormSubmit >> Form.data >> ', this.Forms[options.formName]['data'])

      // 表单校验
      if (options.validate !== false) {
        let validateRes = true
        options.form = options.form || this.$refs[`$${options.formName}Form`] || this.$refs.$Form  // this.$refs.$Form 这种懒人取值方式有风险，只针对单个表单的情况
        if (options.form) {
          options.form.clearValidate()
          if (this._isType(options.validate, 'Array') && options.validate.length > 0) {
            options.form.validateField(options.validate, valid => {
              if (valid) validateRes = false
            })
          } else {
            options.form.validate(valid => {
              if (!valid) validateRes = false
            })
          }
        }

        if (!validateRes) return false
      }

      options.reqOptions = options.reqOptions || {}
      if (this.$vue2xcrudConfig && this._isType(this.$vue2xcrudConfig.reqOptions, 'Object')) options.reqOptions = Object.assign({}, this.$vue2xcrudConfig.reqOptions, options.reqOptions)
      options.reqOptions.url = options.reqOptions.url || this.Forms[options.formName]['action']
      options.reqOptions.method = options.reqOptions.method || this.Forms[options.formName]['method'] || 'post'
      if (options.reqOptions.method == 'get') {
        options.reqOptions.params = JSON.parse(JSON.stringify(
          (this._isType(this.Forms[options.formName]['data'], 'Object') && this._isType(options.reqOptions.params, 'Object')) ?
            Object.assign(this.Forms[options.formName]['data'], options.reqOptions.params) : (options.reqOptions.params || this.Forms[options.formName]['data'])
        ))
      } else {
        options.reqOptions.data = JSON.parse(JSON.stringify(
          (this._isType(this.Forms[options.formName]['data'], 'Object') && this._isType(options.reqOptions.data, 'Object')) ?
            Object.assign(this.Forms[options.formName]['data'], options.reqOptions.data) : (options.reqOptions.data || this.Forms[options.formName]['data'])
        ))
      }

      this.Forms[options.formName]['loading'] = true

      if (this._isType(this.SetFormSubmit, 'Function')) {
        try {
          if (await this.SetFormSubmit.call(this, options) == false) {
            this.Forms[options.formName]['loading'] = false
            return false
          }
        } catch(err) {
          console.error(err)
          this.$message.error('请检查 SetFormSubmit 回调方法中逻辑或语法错误！')
          this.Forms[options.formName]['loading'] = false
          return false
        }
      }

      if (options.reqOptions.url) {
        try {
          options.res = await this.$vue2xcrudAxios(options.reqOptions)
        } catch(err) {
          options.err = err
        }

        if (this._isType(options.callback, 'Function')) {
          try {
            if (await options.callback.call(this, options) == false) {
              this.Forms[options.formName]['loading'] = false
              return false
            }
          } catch(err) {
            console.error(err)
            this.$message.error('请检查 options.callback 回调方法中逻辑或语法错误！')
            this.Forms[options.formName]['loading'] = false
            return false
          }
        }

        if (this.$vue2xcrudConfig && this.$vue2xcrudConfig.resMsg == true && options.resMsg != false) {
          let resMsg = this.Forms[options.formName]['resMsg']
          let resMsg2 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataDetail && this.$vue2xcrudConfig.dataDetail.resMsg
          let resMsg3 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.resMsgKey

          let msg = ''
          let callRes = ''
          if (options.err) {
            callRes = options['err']
            if (options.resMsg == false || options.errMsg == false) {
              this.Forms[options.formName]['loading'] = false
              return false
            } else if (this._isType(options.errMsg, 'Function')) {
              msg = options.errMsg.call(this, callRes)
            }
          } else {
            callRes = options['res']
            if (options.resMsg == false) {
              this.Forms[options.formName]['loading'] = false
              return options
            } else if (this._isType(options.resMsg, 'Function')) {
              msg = options.resMsg.call(this, callRes)
            }
          }

          if (!msg && this._isType(resMsg, 'Function')) msg = resMsg.call(this, options)
          if (!msg && this._isType(resMsg2, 'Function')) msg = resMsg2(options)
          if (!msg) msg = this._isType(resMsg3, 'Function') ? resMsg3(options) : callRes[resMsg3]

          if (options.err) {
            this.$message.error(msg || '操作失败')
          } else {
            this.$message.success(msg || '操作成功')
          }
        }

        this.Forms[options.formName]['loading'] = false
        return options.err ? false : options
      } else {
        try {
          if (this._isType(options.callback, 'Function')) {
            await options.callback.call(this, options)
          }

          this.Forms[options.formName]['loading'] = false
          return options
        } catch(err) {
          console.error(err)
          this.$message.error('操作失败！请检查 options.callback 回调方法中逻辑或语法错误！')
          this.Forms[options.formName]['loading'] = false
          return false
        }
      }
    },
    _isType (obj, type) {
      return Object.prototype.toString.call(obj) === '[object ' + type + ']'
    },
  },
}