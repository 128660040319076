import Vue from 'vue'
import { Message } from 'element-ui'

const $utils = {
  // 判断对象类型
  isType (obj, type) {
    return Object.prototype.toString.call(obj) === '[object ' + type + ']'
  },

  // 货币输入限制
  currencyInput (e) {
    e.target.value = e.target.value.replace(/[^\d.]/g, '')
    e.target.value = e.target.value.replace(/\.{2,}/g, '.')
    e.target.value = e.target.value.replace(/^\./g, '0.')
    e.target.value = e.target.value.replace(/^\d*\.\d*\./g, e.target.value.substring(0,e.target.value.length-1))
    e.target.value = e.target.value.replace(/^0[^.]+/g, '0')
    e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
    return e.target.value
  },
  // 整数输入限制
  intInput (e) {
    e.target.value = e.target.value.replace(/[^\d]/g, '')
    return e.target.value
  },

  // 文件下载
  downloadFromBlob (options = {}) {
    if (!options.fileName || !options.fileData) return false
    let $A = document.createElement('a')
    $A.download = options.fileName
    $A.style.display = 'none'
    $A.href = URL.createObjectURL(new Blob([options.fileData], { type: options.fileType }))
    document.body.appendChild($A)
    $A.click()
    document.body.removeChild($A)
  },
  downloadFromFileReader (options = {}) {
    if (!options.fileName || !options.fileData) return false
    let reader = new FileReader()
    reader.readAsDataURL(options.fileData)
    reader.onload = function (e) {
      let $A = document.createElement('a')
      $A.download = options.fileName
      $A.style.display = 'none'
      $A.href = e.target.result
      document.body.appendChild($A)
      $A.click()
      document.body.removeChild($A)
    }
  },

  // 数组转树结构
  arr2tree (arr, parentId, parentIdName, idName, childrenName) {
    if (!parentId || !parentIdName || !idName || !childrenName) return arr
    var tree = [], treeIndex = 0
    for (var i = 0; i < arr.length; i++) {
      var n = arr[i]
      if ( n[parentIdName] == parentId ) {
        let tmp = $utils.arr2tree(arr, n[idName], parentIdName, idName, childrenName)
        if (tmp.length > 0) n[childrenName] = tmp
        tree[treeIndex] = n
        treeIndex ++
      }
    }
    return tree
  },

  tree2arr(tree = [], arr = []) {
    for (let item of tree) {
      arr.push(item)
      if (item.children && item.children.length) this.tree2arr(item.children, arr)
    }
    return arr
  },

  obj2arr(obj) {
    let arr = []
    for (var a in obj) arr.push(obj[a])
    return arr
  },

  // 根据当前层级 id 查找所有父级
  getParents (data2, nodeId2, parentIdName, idName, childrenName) {
    if (!nodeId2 || !parentIdName || !idName || !childrenName) return data2
    var arrRes = []
    if (data2.length == 0) {
      if (nodeId2) arrRes.unshift(data2)
      return arrRes
    }
    let rev = (data, nodeId) => {
      for (var i = 0; i < data.length; i++) {
        let node = data[i]
        if (node[idName] == nodeId) {
          arrRes.unshift(node)
          rev(data2, node[parentIdName], parentIdName, idName, childrenName)
          break;
        }
        else {
          if (node[childrenName]) {
            rev(node[childrenName], nodeId, parentIdName, idName, childrenName)
          }
        }
      }
      return arrRes
    }
    arrRes = rev(data2, nodeId2, parentIdName, idName, childrenName)
    return arrRes
  },

  // 查找树节点
  /* 用法
    findTreeNode(treeArr, matchValue, {
      findMode: 'findAll', // 默认：findOne，可选：findAll
      matchName: 'name', // 默认：id
      childName: 'children', // 默认：child
      matchMode: 'fullMatch', // 默认match，可选：fullMatch、include
      deepFind: true, // 默认：false
      fullMatch: true // 默认：false
    })
  */
  findTreeNode (treeArr, matchValue, options = {}) {
    options.findMode = options.findMode || 'findOne'
    options.matchName = options.matchName || 'id'
    options.childName = options.childName || 'child'
    let findItems = []
    if (options.deepFind) {
      let deepFind = function (treeArr) {
        for (var i = 0; i < treeArr.length; i++) {
          var n = treeArr[i]

          let matchRes = false
          if (options.matchMode == 'include') {
            matchRes = n[options.matchName].includes(matchValue)
          } else {
            matchRes = options.matchMode == 'fullMatch' ? n[options.matchName] === matchValue : n[options.matchName] == matchValue
          }

          if (matchRes) {
            findItems.push(n)
            if (options.findMode == 'findOne') {
              findItems = [findItems[0]]
              break
            }
          }

          if (n[options.childName]) deepFind(n[options.childName])
        }
      }
      deepFind(treeArr)
    } else {
      treeArr = JSON.parse(JSON.stringify(treeArr))

      while (treeArr.length) {
        let item = treeArr.shift()

        let matchRes = false
        if (options.matchMode == 'include') {
          matchRes = item[options.matchName].includes(matchValue)
        } else {
          matchRes = options.matchMode == 'fullMatch' ? item[options.matchName] === matchValue : item[options.matchName] == matchValue
        }

        if (matchRes) {
          findItems.push(item)
          if (options.findMode == 'findOne') break
        }

        if (item[options.childName]) treeArr = treeArr.concat(item[options.childName])
      }
    }
    return findItems
  },

  getUUID() {
    let arr = URL.createObjectURL(new Blob()).split('/')
    return arr[arr.length - 1]
  },

  copy2clipboard(value, event) {
    const textarea = document.createElement('textarea')
    textarea.value = value || ' '
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('Copy')
    document.body.removeChild(textarea)
    Message({ message: '复制成功', type: 'success' })
  }
}

Vue.prototype.$utils = $utils

export default $utils