import {get, post, del, put } from "../http"
export default {
    // 保存店铺
    shopAdd(p) {
        return post('/api/shop/handle', p);
    },
    // 店铺列表
    shopList(p) {
        return get('api/shop/handle', p);
    },
    shopListSource(p) {
        return get('api/shops', p);
    },
    //删除接口
    shopListRole2(p) {
        return get('api/shop/commerce', p);
    },
    // 店铺详情
    shopDetail(p) {
        return get('api/shop/handle/' + p.id, p);
    },
    shopSave(p) {
        return put('api/shop/handle/' + p.id, p);
    },
    // 店铺删除
    shopDelete(p) {
        return del('api/shop/handle/' + p.id, p);
    },
    //获取电商平台
    getShopPlatform() {
        return get('api/commerce/handle');
    },
    getShopPlatform2() {
        return get('api/admin/commerce');
    },
    //其他页面调用的数据源
    getShopSource() {
        return get('api/shops');
    }
}