<template>
  <div class="AppLeft">
    <div class="head"><img :src="avator" /></div>
    <div class="menu">
      <div href="javascript:;" class="menuItem" v-for="(item, index) in menu" :key="index">
        <div class="main" v-if="item.meta.type == 'menu'">
          <i class="icon iconfont" :class="item.meta.icon"></i>
          <div class="text">{{ item.name || item.meta.title }}</div>
        </div>
        <!--<div class="child" :style="{display: index == 0 ? 'block' : ''}">-->
        <div class="child">
          <div class="menuItem2" v-for="(item2, index2) in item.children" :key="index2" v-if="item2.meta.type == 'menu'" :style="{ height : item2.showChild ? ((item2.children && item2.children.filter((item3) => item3.meta.type == 'menu').length || 0) + 1) * 35 + 'px' : '' }" @mouseenter="$set(item2, 'showChild', true)" @mouseleave="$set(item2, 'showChild', false)">
            <div
              class="main2" :class="{ showChild : item2.showChild }" @click="menuClick(item2, index2, item)"
            >{{item2.name || item2.meta.title }}<i class="icon el-icon-arrow-right" v-show="item2.children && item2.children.length > 0" /></div>
            <div class="child2" :class="{ showChild : item2.showChild }">
              <div class="menuItem3" v-for="(item3, index3) in item2.children" :key="index3" @click="menuClick(item3)" v-if="item3.meta.type == 'menu'">{{ item3.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <a href="javascript:;" class="icon iconfont icon-QRcode qrcode" title="扫码进群">
        <div class="qrcodeImg">
          <img v-if="$store.state.SetUpInfo.qrcode_qun" :src="$APIHost + '/' + $store.state.SetUpInfo.qrcode_qun" />
        </div>
      </a>
      <a href="javascript:;" class="icon iconfont icon-logout" @click="logout()" title="退出登录" />
      <a href="javascript:;" class="icon iconfont icon-shuaxin" @click="$emit('reload')" title="刷新本页" style="font-size:24px;" />
      <a href="javascript:;" class="icon iconfont icon-left-right-switch" @click="$emit('toggle')" title="切换导航栏位置" />
    </div>
  </div>
</template>

<script>
import { accessRoutes } from '@/router'

export default {
  data() {
    return {
      menu: accessRoutes,
      avator: this.$store.state.SetUpInfo.portrait || require('@/assets/img/home/d_tp.png')
    }
  },
  methods: {
    logout() {
      this.$store.commit('Logout')
      this.$router.replace({ name: '登录' })
    },
    menuClick(item, index, pitem) {
      if (item.name && item.name != this.$router.currentRoute.name) {
        this.$router.push({ name: item.name }).catch(() => {})
      }
    }
  }
}
</script>

<style scoped lang="less">

  .AppLeft {position:relative;z-index:100;display:flex;flex-direction:column;justify-content:space-between;width:100%;height:100%;background-color:#171c3a;
    .head {
      img {display:block;width:40px;height:40px;margin:0 auto;padding:10px;}
    }
    .menu {position:relative;z-index:10;height:100%;
      .menuItem {position:relative;display:block;cursor:pointer;
        &:hover {
          .main {background-color:rgba(0,150,255,0.23);}
          .child {display:block;}
        }
        .main {position:relative;padding:10px 0;color:#fff;text-align:center;
          .icon {display:block;width:20px;height:20px;margin:0 auto;font-size:20px;}
          .text {padding-top:8px;line-height:1;}
        }
        .child {display:none;position:absolute;left:70px;top:0px;width:176px;box-shadow:5px 2px 10px #666;
          &:before {content:'';position:absolute;top:-100vh;left:0;right:0;bottom:-100vh;}
        }
      }
      .menuItem2 {line-height:35px;cursor:pointer;position:relative;z-index:0;
        height:35px;overflow:hidden;transition:all .6s;
        &:hover {
          .main2 {background-color:#006eff;color:#fff;
            .icon:before {transform:rotate(90deg);}
          }
        }
        .main2 {position:relative;height:35px;padding:0 15px;background-color:#dedede;
          .icon {position:absolute;top:0;right:0;display:flex;padding:0 5px;height:100%;font-size:16px;justify-content:center;align-items:center;
            &:before {transform:rotate(0deg);transition:transform .3s;}
          }
        }
        .child2 {
          .menuItem3 {height:35px;background-color:#fff;padding:0 20px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;
            &:hover {color:#000;background-color:#ffc;}
          }
        }
      }
    }
    .btns {padding:15px 0 15px;
      a {display:block;color:#fff;padding:15px 0;text-align:center;font-size:20px;}
      .qrcode {position:relative;font-size:26px;
        &:hover .qrcodeImg {display:block;}
        .qrcodeImg {display:none;position:absolute;left:70px;bottom:0;padding:20px;background-color:#fff;box-shadow:0 0 5px #bbb;
          img {width:180px;}
        }
      }
    }
  }
</style>
