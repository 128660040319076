// 快递模块相关接口
import {get, post, del, put } from "../http"
export default {
    // 获取快递公司列表
    expressList(p) {
        return get('api/admin/logistics_companies', p);
    },
    // 保存订单的快递单号
    saveWaybillCode(p) {
        return post('api/commerce/orders_waybill', p);
    },
    // 获取菜鸟账户信息
    getCainiaoInfo(p) {
        return get('api/commerce/shops', p);
    },
    // 保存菜鸟地址和账户信息
    saveCainiaoAccount(p) {
        return post('api/commerce/logisticscompanieswaybill', p);
    },
    // 获取菜鸟默认信息、账户
    getCainiaoAccount(p) {
        return get('api/commerce/waybill_shop', p);
    },
    // 获取所有菜鸟模版
    getCainiaoTemplate(p) {
        return get('api/commerce/templates_shop', p);
    },
    // 获取用户创建的模版列表
    getUserCainiaoTemplate(p) {
        return get('api/commerce/templates_shop_list', p);
    },
    // 获取单个菜鸟末班
    getCainiaoDetail(p) {
        return get('/api/commerce/templates_shop/' + p.id);
    },
    // 保存用户编辑的菜鸟模版
    saveUserCainiaoTemplate(p) {
        return post('api/commerce/templates_shop', p);
    },
    // 删除菜鸟模版
    delUserCainiaoTemplate(p) {
        return del('/api/commerce/templates_shop/' + p.id);
    },
    // 菜鸟批量获取单号
    // orders : {
    //     "2043130387766": {
    //         "cainiao_waybill_address_id": 1
    //     },
    //     "9584730318575": {
    //         "cainiao_waybill_address_id": 1
    //     },
    //     "6008708876756": {
    //         "cp_code": "YUNDA",
    //         "waybill_code": "1234567890"
    //     }
    // }
    saveWaybillCodeCainiao(p) {
        return post('api/commerce/orders_waybill', p);
    },
    // 获取打印新单号数据
    canniaoPrintNewNumber(p) {
        return post('api/commerce/waybillnewprint', p);
    },
    // 获取打印旧单号数据
    canniaoPrintOldNumber(p) {
        return post('api/commerce/waybilloldprint', p);
    },
    //保存菜鸟默认账户
    saveLogisticscompanieswaybill(p) {
        return post('/api/commerce/logisticscompanieswaybill', p);
    }


}