import Vue from 'vue'
import { Message } from 'element-ui'
import $app from '@/imports/app'

const $lodopPrint = {
  shipOrder (printerNO, templateInfo, orderInfo, fieldInfo, mode) {
    let paper = JSON.parse(templateInfo.paper)
    let dragbox = JSON.parse(templateInfo.dragbox)
    let isPreviewOpen = false

    orderInfo.forEach((item, index) => {
      let printName = 'PrintShipOrder' + index + ' ' + new Date().toLocaleString().replaceAll('/', '-')
      window.LODOP.SET_LICENSES('广东东坡纸业有限公司', '13418061AB0D5B511F41D05CDFBEE5E8', '', '')
      window.LODOP.PRINT_INIT(printName)
      window.LODOP.SET_PRINT_PAGESIZE(0, paper.width * 10, paper.height * 10, printName)
      window.LODOP.SET_PRINTER_INDEX(printerNO)

      let goodsBox = dragbox.find(item2 => item2.type == 'shipment')
      let pages = Math.ceil(item.list_info.length / goodsBox.pageSize)
      for (let p = 0; p < pages; p++) {
        // 特殊订单背景水印
        if (item.is_ms === 'return') {
          window.LODOP.ADD_PRINT_TEXTA(0, 1, 0, '100%', '100%', '以此单为准，前单作废，以此单为准')
          window.LODOP.SET_PRINT_STYLEA(0, 'FontSize', 11.5)
          // window.LODOP.SET_PRINT_STYLEA(0, 'Angle', -45)
          window.LODOP.SET_PRINT_STYLEA(0, 'FontColor', '#999999')
        }

        dragbox.forEach((item2, index2) => {
          if (item2.type == 'barCode') {
            this._printBarCode(item, item2)
          } else if (item2.type == 'text') {
            // 处理打印文本内容
            // 处理特殊字段
            let sourceValue = item[item2.source]
            switch (item2.source) {
              case 'shop_name':
                sourceValue = item.shop_info.name
                break;
              default:
            }
            let printText = item2.text
            if (sourceValue !== undefined) printText = printText ? printText + ':' + sourceValue : sourceValue
            if (item2.source == 'currentPage') printText = '第 ' + (p + 1) + ' 页'
            this._printText(printText, item2)
          } else if (item2.type == 'shipment') {
            let table = document.createElement('table')
            for (var a in item2.tableStyle) {
              table.style[a] = item2.tableStyle[a]
            }
            // 构造表头
            let tr1 = document.createElement('tr')
            for (var i = 0; i < item2.rows[0].length; i++) {
              var n = item2.rows[0][i]
              let td = document.createElement('td')
              td.setAttribute('cellspacing', 0)
              for (var a in n.style) {
                td.style[a] = n.style[a]
              }
              td.innerHTML = n.valueShow
              if (!item2.borderLine) td.style.border = 'none'
              tr1.appendChild(td)
            }
            table.appendChild(tr1)

            let total = {
              num: 0,
              price: 0,
              payment: 0
            }
            // 构造表体
            for (var i = p * goodsBox.pageSize; i < p * goodsBox.pageSize + goodsBox.pageSize; i++) {
              var n = item.list_info[i]
              if (n) {
                let tr2 = document.createElement('tr')
                for (let j = 0; j < item2.rows[0].length; j++) {
                  var m = item2.rows[0][j]
                  let td = document.createElement('td')
                  td.setAttribute('cellspacing', 0)
                  for (var a in m.style) {
                    td.style[a] = m.style[a]
                  }
                  if (!item2.borderLine) td.style.border = 'none'
                  if (m.valueShow == '序号') {
                    td.innerHTML = i + 1
                  } else {
                    let field = ''
                    for (var a in fieldInfo) {
                      for (var b in fieldInfo[a]) {
                        if (fieldInfo[a][b] == m.valueShow) {
                          field = b
                          break
                        }
                      }
                      if (field) break
                    }
                    if (['price'].includes(field)) {
                      td.innerHTML = $app.fen2yuan(n[field])
                    } else {
                      td.innerHTML = n[field]
                    }
                    // 计算总数
                    if (total[field] !== undefined) {
                      if (['price', 'payment'].includes(field)) {
                        total[field] += $app.fen2yuan(n[field])
                      } else {
                        total[field] += n[field]
                      }
                    }
                  }
                  tr2.appendChild(td)
                  table.appendChild(tr2)
                }
              } else {
                if (item2.emptyLine) {
                  let tr2 = document.createElement('tr')
                  let td = document.createElement('td')
                  td.colSpan = item2.rows[0].length
                  if (!item2.borderLine) {
                    td.style.border = 'none'
                  } else {
                    td.style.border = '1px solid #000'
                  }
                  tr2.appendChild(td)
                  table.appendChild(tr2)
                }
              }
            }
            // 构造表尾
            let tr3 = document.createElement('tr')
            for (var i = 0; i < item2.rows[0].length; i++) {
              var n = item2.rows[0][i]
              let td = document.createElement('td')
              td.setAttribute('cellspacing', 0)
              for (var a in n.style) {
                td.style[a] = n.style[a]
              }
              if (!item2.borderLine) td.style.border = 'none'
              if (n.valueShow == '序号') {
                td.innerHTML = '汇总:'
              } else {
                let field = ''
                for (var a in fieldInfo) {
                  for (var b in fieldInfo[a]) {
                    if (fieldInfo[a][b] == n.valueShow) {
                      field = b
                      break
                    }
                  }
                  if (field) break
                }
                if (total[field] !== undefined) {
                  td.innerHTML = total[field]
                }
              }

              tr3.appendChild(td)
            }
            table.appendChild(tr3)

            window.LODOP.ADD_PRINT_HTM(item2.boxObject.top, item2.boxObject.left, item2.boxObject.width, item2.boxObject.height, table.outerHTML)
          } else if (item2.type == 'qr') {
            this._printQRCode(item, item2)
          } else if (item2.type == 'lineBox') {
            this._printLine(item2)
          } else if (item2.type == 'image') {
            this._printImage(item2)
          }
        })

        //window.LODOP.NewPage()
        if (mode == 'PREVIEW') {
          if (!isPreviewOpen) window.LODOP.PREVIEW()
          isPreviewOpen = true
        } else {
          window.LODOP.PRINT()
        }
      }
    })
  },
  prepareOrder (printerNO, templateInfo, orderInfo, fieldInfo, mode) {
    let paper = JSON.parse(templateInfo.paper)
    let dragbox = JSON.parse(templateInfo.dragbox)
    let isPreviewOpen = false

    let printName = '打印备货单' + ' ' + new Date().toLocaleString().replaceAll('/', '-')
    window.LODOP.SET_LICENSES('广东东坡纸业有限公司', '13418061AB0D5B511F41D05CDFBEE5E8', '', '')
    window.LODOP.PRINT_INIT(printName)
    window.LODOP.SET_PRINT_PAGESIZE(0, paper.width * 10, paper.height * 10, printName)
    window.LODOP.SET_PRINTER_INDEX(printerNO)

    let goodsBox = dragbox.find(item2 => item2.type == 'prepare')
    let pages = Math.ceil(orderInfo.length / goodsBox.pageSize)
    for (let p = 0; p < pages; p++) {
      let item = orderInfo[p]
      dragbox.forEach((item2, index2) => {
        if (item2.type == 'barCode') {
          this._printBarCode(item, item2)
        } else if (item2.type == 'text') {
          // 处理打印文本内容
          // 处理特殊字段
          let sourceValue = item[item2.source]
          let printText = item2.text
          if (sourceValue !== undefined) printText = printText ? printText + ':' + sourceValue : sourceValue
          if (item2.source == 'currentPage') printText = '第 ' + (p + 1) + ' 页'
          this._printText(printText, item2)
        } else if (item2.type == 'prepare') {
          let table = document.createElement('table')
          for (var a in item2.tableStyle) {
            table.style[a] = item2.tableStyle[a]
          }
          // 构造表头
          let tr1 = document.createElement('tr')
          for (var i = 0; i < item2.rows[0].length; i++) {
            var n = item2.rows[0][i]
            let td = document.createElement('td')
            td.setAttribute('cellspacing', 0)
            for (var a in n.style) {
              td.style[a] = n.style[a]
            }
            td.innerHTML = n.valueShow
            if (!item2.borderLine) td.style.border = 'none'
            tr1.appendChild(td)
          }
          table.appendChild(tr1)

          let total = {
            num: 0
          }
          // 构造表体
          for (var i = p * goodsBox.pageSize; i < p * goodsBox.pageSize + goodsBox.pageSize; i++) {
            var n = orderInfo[i]
            if (n) {
              let tr2 = document.createElement('tr')
              for (let j = 0; j < item2.rows[0].length; j++) {
                var m = item2.rows[0][j]
                let td = document.createElement('td')
                td.setAttribute('cellspacing', 0)
                for (var a in m.style) {
                  td.style[a] = m.style[a]
                }
                if (!item2.borderLine) td.style.border = 'none'
                if (m.valueShow == '序号') {
                  td.innerHTML = i + 1
                } else {
                  let field = ''
                  for (var a in fieldInfo) {
                    if (fieldInfo[a] == m.valueShow) {
                      field = a
                      break
                    }
                  }
                  if (field == 'pic_path') {
                    let img = document.createElement('img');
                    img.style.width = '40px'
                    img.style.height = '40px'
                    img.setAttribute('src', n[field])
                    td.appendChild(img)
                  } else {
                    td.innerHTML = n[field]
                  }
                  // 计算总数
                  if (total[field] !== undefined) {
                    total[field] += n[field]
                  }
                }
                tr2.appendChild(td)
                table.appendChild(tr2)
              }
            } else {
              if (item2.emptyLine) {
                let tr2 = document.createElement('tr')
                let td = document.createElement('td')
                td.colSpan = item2.rows[0].length
                if (!item2.borderLine) {
                  td.style.border = 'none'
                } else {
                  td.style.border = '1px solid #000'
                }
                tr2.appendChild(td)
                table.appendChild(tr2)
              }
            }
          }
          // 构造表尾
          let tr3 = document.createElement('tr')
          for (var i = 0; i < item2.rows[0].length; i++) {
            var n = item2.rows[0][i]
            let td = document.createElement('td')
            td.setAttribute('cellspacing', 0)
            for (var a in n.style) {
              td.style[a] = n.style[a]
            }
            if (!item2.borderLine) td.style.border = 'none'
            if (n.valueShow == '序号') {
              td.innerHTML = '汇总:'
            } else {
              let field = ''
              for (var a in fieldInfo) {
                if (fieldInfo[a] == n.valueShow) {
                  field = a
                  break
                }
                if (field) break
              }
              if (total[field] !== undefined) {
                td.innerHTML = total[field]
              }
            }

            tr3.appendChild(td)
          }
          table.appendChild(tr3)

          window.LODOP.ADD_PRINT_HTM(item2.boxObject.top, item2.boxObject.left, item2.boxObject.width, item2.boxObject.height, table.outerHTML)
        } else if (item2.type == 'qr') {
          this._printQRCode(item, item2)
        } else if (item2.type == 'lineBox') {
          this._printLine(item2)
        } else if (item2.type == 'image') {
          this._printImage(item2)
        }
      })

      //window.LODOP.NewPage()
      if (mode == 'PREVIEW') {
        if (!isPreviewOpen) window.LODOP.PREVIEW()
        isPreviewOpen = true
      } else {
        window.LODOP.PRINT()
      }
    }
  },
  _printText (printText, boxItem) {
    if (boxItem.source == 'currentDatetime') printText = new Date().toLocaleString().replaceAll('/', '-')

    window.LODOP.ADD_PRINT_TEXTA(0, boxItem.boxObject.top, boxItem.boxObject.left, boxItem.boxObject.width, boxItem.boxObject.height, printText)
    // 处理打印文本样式
    switch (boxItem.boxReverse.textAlign) {
      case 'center':
        window.LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
        break;
      case 'right':
        window.LODOP.SET_PRINT_STYLEA(0, 'Alignment', 3)
        break;
      default:
    }
    window.LODOP.SET_PRINT_STYLEA(0, 'FontName', boxItem.textObject.fontFamily)
    window.LODOP.SET_PRINT_STYLEA(0, 'FontSize', boxItem.textObject.fontSizeNum)
    if (boxItem.textObject.color) window.LODOP.SET_PRINT_STYLEA(0, 'FontColor', boxItem.textObject.color)
    if (boxItem.textObject.fontWeight == 'bold') window.LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
    if (boxItem.textObject.fontStyle == 'italic') window.LODOP.SET_PRINT_STYLEA(0, 'Italic', 1)
    if (boxItem.textObject.textDecoration == 'underline') window.LODOP.SET_PRINT_STYLEA(0, 'Underline', 1)
  },
  _printBarCode (dataItem, boxItem) {
    let boxObject = { ...boxItem.boxObject }
    if (boxItem.boxObject.deg == 90) {
      boxObject.width = boxItem.boxObject.height
      boxObject.height = boxItem.boxObject.width
      boxObject.top -= (boxObject.height - boxObject.width) / 2
      boxObject.left += (boxObject.height - boxObject.width) / 2
    }
    window.LODOP.ADD_PRINT_BARCODE(boxObject.top, boxObject.left, boxObject.width, boxObject.height, boxItem.format, dataItem[boxItem.source] || boxItem.staticText)
    if (boxItem.boxObject.deg == 90) window.LODOP.SET_PRINT_STYLEA(0, 'Angle', 90)
  },
  _printQRCode (dataItem, boxItem) {
    window.LODOP.ADD_PRINT_BARCODE(boxItem.boxObject.top, boxItem.boxObject.left, boxItem.boxObject.width, boxItem.boxObject.height, 'QRCode', dataItem[boxItem.source] || boxItem.staticText)
  },
  _printLine (boxItem) {
    let div = document.createElement('div')
    div.style['top'] = boxItem.boxObject['top'] + 'px'
    div.style['left'] = boxItem.boxObject['left'] + 'px'
    div.style['borderBottom'] = boxItem.lineStyle['borderBottom']
    div.style['width'] = (boxItem.lineStyle['borderBottom'] != 'none' ? boxItem.boxObject['width'] : 0) + 'px'
    div.style['borderLeft'] = boxItem.lineStyle['borderLeft']
    div.style['height'] = (boxItem.lineStyle['borderLeft'] != 'none' ? boxItem.boxObject['height'] : 0) + 'px'

    let printWidth = boxItem.lineStyle['borderLeft'] != 'none' ? boxItem.lineStyle['borderLeft'][0] : boxItem.boxObject['width']
    let printHeight = boxItem.lineStyle['borderBottom'] != 'none' ? boxItem.lineStyle['borderBottom'][0] : boxItem.boxObject['height']

    window.LODOP.ADD_PRINT_HTM(boxItem.boxObject.top, boxItem.boxObject.left, printWidth, printHeight, div.outerHTML)
  },
  _printImage (boxItem) {
    let img = document.createElement('img')
    img.setAttribute('src', boxItem.url)
    img.style.width = '100%'
    img.style.height = '100%'
    window.LODOP.ADD_PRINT_HTM(boxItem.boxObject.top, boxItem.boxObject.left, boxItem.boxObject.width, boxItem.boxObject.height, img.outerHTML)
  }
}

Vue.prototype.$lodopPrint = $lodopPrint

export default $lodopPrint