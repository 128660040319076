import Vue from 'vue'
//import { dataTable, dataDetail, modal } from 'vue2xcrud/mixins'
import dataTable from '@/dataTable'
import dataDetail from '@/dataDetail'
import modal from '@/modal'

import DataTableFilterForm from 'vue2xcrud/components/FilterForm'
import DataTablePagination from 'vue2xcrud/components/DataPage'

// 此全局配置与后端返回的数据结构相关，不能随便修改
// 若修改发现接口获取不到数据则慎重修改
// 可以在单独请求配置中设定如果获取接口数据
const $vue2xcrudConfig = {
  resDataKey: 'data',
  resMsgKey: 'msg',
  resMsg: true,
  console: false,
  dataTable: {
    columnWidth: {
      '复选': 60,
      '排序': 80,

      'ID': 130,
      '姓名': 130,
      '电话': 130,

      '图片': 100,
      '名称': 200,
      '内容': 200,

      '时间': 160,

      '金额': 88,
      '数量': 68,
      '单位': 60,

      '类型': 80,
      '状态': 80,

      '操作': 90
    },
    pageNumName: 'page',
    pageSizeName: 'limit',
    pageSize: 20,
    tableItems: res => res['data']['data'] || [],
    tableTotal: res => res['data']['total'] || 0,
    // removeResMsg: res => res.msg,
    exportFileData: res => res.data,
    exportFileType: res => 'xls',
    pageLayout: 'total, sizes, prev, pager, next, jumper'
  },
  dataDetail: {
    resData: res => res['data'],
    //resMsg: res => res.msg
  },
  model: {
    width: '1200px'
  }
}

Vue.prototype.$vue2xcrudConfig = $vue2xcrudConfig

Vue.component('DataTableFilterForm', DataTableFilterForm)
Vue.component('DataTablePagination', DataTablePagination)

Vue.mixin(dataTable)
Vue.mixin(dataDetail)
Vue.mixin(modal)

export default $vue2xcrudConfig