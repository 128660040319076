import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import utils from '@/imports/utils'
import { Message } from 'element-ui'
Vue.use(VueRouter)

// 公共路由
let baseRouteName = '您店铺的专业管家-店铺总管'

let commonRoutes = [
  {
    path: '/404', name: '404',
    meta: { title: baseRouteName },
    component: () => import('@/views/主要/404/404')
  }
]

let whiteRoutes = [
  {
    path: '/home', name: '登录',
    meta: { title: baseRouteName },
    component: () => import('@/views/主要/登录/登录')
  },
  {
    path: '/register', name: '注册',
    meta: { title: baseRouteName },
    component: () => import('@/views/主要/注册/注册')
  },
  {
    path: '/setRole', name: '选择角色',
    meta: { title: baseRouteName },
    component: () => import('@/views/主要/选择角色/选择角色')
  }
]

// 权限路由
import role1Route from './role1/all'
import role2Route from './role2/all'
/*
let files = require.context('./routes', false, /\.js$/)
let permissionRoutes = []
files.keys().forEach(key => {
  permissionRoutes = permissionRoutes.concat(files(key).default)
})
*/
import 电商管理 from './role3/电商管理'
import 采购 from './role3/采购'
import 库存 from './role3/库存'
import 销售 from './role3/销售'
import 资金 from './role3/资金'
import 报表 from './role3/报表'
import 设置 from './role3/设置'
import 资料 from './role3/资料'
let role3Route = [电商管理, 采购, 库存, 销售, 资金, 报表, 设置, 资料]

// 路由注册
const createRouter = () => new VueRouter({
  // mode: 'history',
  routes: whiteRoutes.concat(commonRoutes)
})
const router = createRouter()

// 路由守卫
let accessRoutes = []
let redirectRoute = {}
router.beforeEach((to, from, next) => {
  if (store.state.HasPermission) {
    if (whiteRoutes.map((item, index) => item.path).includes(to.path)) {
      next('/')  // 登录后不能访问 whiteRoutes 页面
    }
    next()
  } else {
    let userinfo = JSON.parse(localStorage.getItem('userinfo'))
    if (userinfo) {
      let auth = JSON.parse(localStorage.getItem('auth'))
      switch (userinfo.register_role_id) {
        case 1:
          accessRoutes = getAccessRoutes(auth, role1Route)
          redirectRoute = getRedirectRoute(accessRoutes, '模版列表', next)
          if (!redirectRoute) return false
          router.addRoute(redirectRoute)
          break;
        case 2:
          accessRoutes = getAccessRoutes(auth, role2Route)
          redirectRoute = getRedirectRoute(accessRoutes, '近三个月订单', next)
          if (!redirectRoute) return false
          router.addRoute(redirectRoute)
          break;
        case 3:
          accessRoutes = getAccessRoutes(auth, role3Route)
          redirectRoute = getRedirectRoute(accessRoutes, '数据概况', next)
          if (!redirectRoute) return false
          router.addRoute(redirectRoute)
          break;
        default:
      }

      accessRoutes.forEach(item => { router.addRoute(item) })
      router.addRoute({ path: '*', redirect: { name: '404' } })  // 需要最后添加，登录后浏览器输入路由不能匹配时自动跳转 404

      store.state.HasPermission = true
      store.state.UserInfo = userinfo
      next({ ...to, replace: true })
    } else {
      if (whiteRoutes.map((item, index) => item.path).includes(to.path)) {
        next()
      } else {
        next({ name: '登录' })
      }
    }
  }
})

router.afterEach((to, from) => {
  document.title = to.meta.title || to.name
  if (to.name) {
    store.state.CurrentMenuTab = to.name // 设置当前便签
    // 设置便签数组
    let MenuTabs = JSON.parse(localStorage.getItem('MenuTabs')) || []
    if (to.meta.type == 'menu' && !MenuTabs.includes(to.name)) MenuTabs.push(to.name)
    localStorage.setItem('MenuTabs', JSON.stringify(MenuTabs))
    store.state.MenuTabs = MenuTabs
  }
})

function resetRouter() {
  router.matcher = createRouter().matcher
}

function getAccessRoutes(authRoute, roleRoute) {
  let resRoute = []
  filterAccessRoutes(resRoute, authRoute, roleRoute)
  return resRoute
}

function filterAccessRoutes(resRoute, authRoute, roleRoute) {
  for (var i = 0; i < authRoute.length; i++) {
    var n = authRoute[i]
    var matchRoleRoute = ''
    var matchNeedRoute = ''
    for (var j = 0; j < roleRoute.length; j++) {
      var m = roleRoute[j]
      if (n.name == (m.name || m.meta.title)) {
        matchRoleRoute = m
        resRoute.push({ ...m, children: [] })
      }

      if (m.meta.type == 'need' && !resRoute.find((item, index) => (item.name || item.meta.title) == (m.name || m.meta.title))) {
        resRoute.push({ ...m, children: [] })
      }
    }

    if (n.child && n.child.length > 0 && matchRoleRoute && matchRoleRoute.children && matchRoleRoute.children.length > 0) {
      let resRouteIndex = resRoute.findIndex(item => (item.name || item.meta.title) == (matchRoleRoute.name || matchRoleRoute.meta.title))
      if (resRouteIndex > -1) {
        filterAccessRoutes(resRoute[resRouteIndex].children, n.child, matchRoleRoute.children)
      }
    }
  }
}

function getRedirectRoute(accessRoutes, name, next) {
  let accessRoutesArr = utils.tree2arr(accessRoutes)
  if (accessRoutesArr.find(item => item.name == name)) {
    return { path: '/', redirect: { name } }
  } else {
    let findRes = accessRoutesArr.find(item => item.name && item.meta.type == 'menu')
    if (findRes) {
      return { path: '/', redirect: { name: findRes.name } }
    } else {
      store.commit('Logout')
      setTimeout(() => {
        Message({ message: '暂无访问权限！', type: 'error' })
      })
      next({ name: '登录' })
      return false
    }
  }
}

Vue.prototype.$resetRouter = resetRouter

export {
  accessRoutes,  // 导出到菜单
  resetRouter
}
export default router