import {get, post, del, put, getFile } from "../http"
export default {
    // 添加采购单
    purchaseAdd(p) {
        return post('api/purchase/apply', p);
    },
    purchaseList(p) {
        return get('api/purchase/apply', p);
    },
    // 采购单详情
    purchaseDetail(p) {
        return get('api/purchase/apply/' + p.id, p);
    },
    // 修改采购单
    purchaseSave(p) {
        return put('api/purchase/apply/' + p.id, p);
    },
    // 添加采购单
    purchaseDelete(p) {
        return del('api/purchase/apply/' + p.id, p);
    },
    //各种数据源
    purchaseSource(p) {
        return get('api/purchase/apply_source', p);
    },
    // 商品弹窗
    purchaseApplyGoods(p) {
        return get('api/purchase/apply_goods', p);
    },
    // 导出excel
    purchaseExcel(p) {
        return getFile('api/purchase/apply_excel', p);
    },
    // 审核
    purchaseApply(p) {
        return post('api/purchase/apply_examine', p);
    },
    //上传文件
    purchase_file(p) {
        return post('api/admin/file', p);
    }
}