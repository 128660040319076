import {get, post, del, put, getFile } from "../http"

export default {
    confirmStock() {
        return post('api/warehouse/beginning/confirm');
    },
    // 初始库存
    stockInit(p) {
        return get('/api/warehouse/beginning/handle', p);
    },
    stockInitSource(p) {
        return get('/api/warehouse/beginning/source', p);
    },
    downInitExcel(p) {
        return getFile('/api/warehouse/beginning/export', p);
    },
    uploadInitExcel(p) {
        return post('/api/warehouse/beginning/import', p);
    },
    // 表单保存库存
    updateStock(p) {
        return post('/api/warehouse/beginning/input', p);
    },

    // 库存列表
    stockList(p) {
        return get('/api/warehouse/query/handle', p);
    },
    // 库存分布
    stockDistribution(p) {
        return get('/api/warehouse/query/distribution/' + p.id, p);
    },
    stockLogs(p) {
        let url = '/api/warehouse/query/detailed';
        return get(url, p);
    }
}