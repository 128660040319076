import Vue from 'vue'
import { Message, MessageBox } from 'element-ui'

// source => https://open.taobao.com/doc.htm?docId=107014&docType=1
const $cainiaoPrint = {
  socket: '',
  printers: '',
  init() {
    if (!this.socket) this.doConnect()

    this.socket.onopen = () => {
      this.socket.send(JSON.stringify(this.getRequestObject('getPrinters')))
    }
    this.socket.onmessage = (e) => {
      var res = JSON.parse(e.data)
      if (res.cmd == 'getPrinters') {
        this.printers = res.printers
        this.isType(this.getPrintersDone, 'Function') && this.getPrintersDone.call(this)
      } else if (res.cmd == 'printerConfig') {
        //printConfigHandler(res)
      }
    }
    this.socket.onclose = () => {
      MessageBox({ title: '警告', message: '菜鸟打印组件断开连接或是未安装，将无法正常打印，<br />请<a href="https://cainiao-oss-sh-read.oss-cn-shanghai.aliyuncs.com/waybill-print/cainiao-x-print/prod/win/cainiao-x-print-win-86.exe">点击下载</a>安装后刷新页面重试', type: 'warning', dangerouslyUseHTMLString: true, center: true }).catch(() => {})
      this.socket = ''
      this.printers = ''
    }
  },
  doConnect() {
    //this.socket = new WebSocket('ws://localhost:13528')
    //如果是https的话，端口是13529
    //socket = new WebSocket('wss://localhost:13529')
    //let wsUrl = 'ws://localhost:' + (location.protocol == 'http:' ? '13528' : '13529')
    this.socket = new WebSocket('ws://localhost:13528')
  },

  /***
   *
   * 获取请求的UUID，指定长度和进制,如
   * getUUID(8, 2)   //"01001010" 8 character (base=2)
   * getUUID(8, 10) // "47473046" 8 character ID (base=10)
   * getUUID(8, 16) // "098F4D35"。 8 character ID (base=16)
   *
   */
  getUUID(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [], i;
    radix = radix || chars.length;
    if (len) {
      for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
    } else {
      var r;
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random()*16;
          uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
        }
      }
    }
    return uuid.join('');
  },

  /***
   * 构造request对象
   */
  getRequestObject(cmd) {
    return {
      requestID: this.getUUID(8, 16),
      version: '1.0',
      cmd
    }
  },

  /**
   * 打印电子面单
   * printer 指定要使用那台打印机
   * waybillArray 要打印的电子面单的数组
   */
  doPrint(data) {
    var request = this.getRequestObject('print')
    request.task = {
      taskID: this.getUUID(8,10),
      preview: false,
      printer: data.print_name,
      documents: [{
        documentID: data.waybill_code,
        contents: [data.print_data, data.custom]
      }]
    }
    this.socket.send(JSON.stringify(request))
  },

  isType (obj, type) {
    return Object.prototype.toString.call(obj) === '[object ' + type + ']'
  },
}

Vue.prototype.$cainiaoPrint = $cainiaoPrint

export default $cainiaoPrint