export default {
  data () {
    return {
      DataTables: {
      }
    }
  },
  created () {
    for (let k in this.DataTables) {
      if (!this._isType(this.DataTables[k], 'Object')) continue
      this._dataTableInit(k)
    }
  },
  computed: {
    ColumnWidth () {
      return function (times, label) {
        let res = 0
        if (times && !label) {
          if (this._isType(times, 'Number')) {
            res = times > 20 ? times : 20 * times // 默认一倍宽度为20px
          } else if (this._isType(times, 'String')) {
            res = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.columnWidth && this.$vue2xcrudConfig.dataTable.columnWidth[times]
          }
        }
        if (times && label) {
          res = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.columnWidth && this.$vue2xcrudConfig.dataTable.columnWidth[label] * times
        }
        return res
      }
    }
  },
  methods: {
    // 查询数据表格
    async DataTableQuery (options = {}) {
      this._dataTableInit(options.tableName)
      let dataTable = this.DataTables[options.tableName]

      options.reqOptions = options.reqOptions || {}
      options.reqOptions.url = options.reqOptions.url || dataTable.queryUrl
      if (!options.reqOptions.url) return false

      options.reqOptions.params = this._isType(options.reqOptions.params, 'Object') ? Object.assign(dataTable.queryParams, options.reqOptions.params) : (options.reqOptions.params || dataTable.queryParams)
      options.reqOptions.method = options.reqOptions.method || 'get'

      dataTable.loading = true

      if (this._isType(this.SetDataTableQuery, 'Function')) {
        try {
          if (await this.SetDataTableQuery.call(dataTable, options) == false) {
            dataTable.loading = false
            return false
          }
        } catch(err) {
          console.error(err)
          this.$message.error('请检查 SetDataTableQuery 回调方法中逻辑或语法错误！')
          dataTable.loading = false
          return false
        }
      }

      try {
        options.res = await this.$vue2xcrudAxios(options.reqOptions)
      } catch(err) {
        options.err = err
      }

      if (!options.err) {
        if (this._isType(this.DataTableSet, 'Function')) {
          await this.DataTableSet.call(dataTable, options)
        }

        let tableItems = dataTable.queryRes && dataTable.queryRes.tableItems
        let tableItems2 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.tableItems
        if (typeof tableItems === 'undefined' && typeof tableItems2 === 'undefined') {
          console.warn(`请在 $vue2xcrudConfig.DataTable 或 DataTables.${options.tableName}.queryRes 中设定 tableItems`)
        }
        dataTable.items = this._isType(tableItems, 'Function') ? tableItems.call(dataTable, options.res) : this._isType(tableItems2, 'Function') ? tableItems2(options.res) : []

        let tableTotal = dataTable.queryRes && dataTable.queryRes.tableTotal
        let tableTotal2 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.tableTotal
        if (typeof tableTotal === 'undefined' && typeof tableTotal2 === 'undefined') {
          console.warn(`请在 $vue2xcrudConfig.DataTable 或 DataTables.${options.tableName}.queryRes 中设定 tableTotal`)
        }
        dataTable.total = this._isType(tableTotal, 'Function') ? tableTotal.call(dataTable, options.res) : this._isType(tableTotal2, 'Function') ? tableTotal2(options.res) : 0
      }

      dataTable.loading = false
    },
    // 单个删除
    async DataTableRemove (options = {}) {
      this._dataTableInit(options.tableName)
      let dataTable = this.DataTables[options.tableName]

      if (options.noConfirm != true) {
        let confirmRes = ''
        try {
          confirmRes = await this.$confirm(options.confirmText || '确认删除数据？', '警告', { type: 'warning' })
        } catch(err) {
          confirmRes = err
        }
        if (confirmRes != 'confirm') return false
      }

      options.item = this._isType(options.item, 'Object') ? options.item : {}
      this.$set(options.item, 'RemoveLoading', true)

      if (dataTable.removeUrl || (options.reqOptions && options.reqOptions.url)) {
        options.reqOptions = options.reqOptions || {} // 当只有 dataTable.removeUrl 时，options.reqOptions 必须初始化
        if (this.$vue2xcrudConfig && this._isType(this.$vue2xcrudConfig.reqOptions, 'Object')) options.reqOptions = Object.assign({}, this.$vue2xcrudConfig.reqOptions, options.reqOptions)
        options.reqOptions.url = options.reqOptions.url || (options.reqOptions.params ? dataTable.removeUrl : dataTable.removeUrl + options.id)
        options.reqOptions.method = options.reqOptions.method || 'delete'

        if (this._isType(this.SetDataTableRemove, 'Function')) {
          try {
            if (await this.SetDataTableRemove.call(dataTable, options) == false) {
              options.item.RemoveLoading = false
              return false
            }
          } catch(err) {
            console.error(err)
            this.$message.error('请检查 SetDataTableRemove 回调方法中逻辑或语法错误！')
            options.item.RemoveLoading = false
            return false
          }
        }

        try {
          options.res = await this.$vue2xcrudAxios(options.reqOptions)
        } catch(err) {
          options.err = err
        }

        if (!options.err && typeof options.index != 'undefined') {
          dataTable.items.splice(options.index, 1)
          if (dataTable.total > 0) dataTable.total -= 1
        }

        if (this._isType(options.callback, 'Function')) {
          await options.callback.call(dataTable, options)
        }

        if (this.$vue2xcrudConfig && this.$vue2xcrudConfig.resMsg == true && options.resMsg != false) {
          let resMsg = dataTable.removeRes && dataTable.removeRes.resMsg
          let resMsg2 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.removeResMsg
          let resMsg3 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.resMsgKey

          let msg = ''
          let callRes = ''
          if (options.err) {
            callRes = options['err']
            if (options.resMsg == false || options.errMsg == false) {
              options.item.RemoveLoading = false
              return false
            } else if (this._isType(options.errMsg, 'Function')) {
              msg = options.errMsg.call(dataTable, callRes)
            }
          } else {
            callRes = options['res']
            if (options.resMsg == false) {
              options.item.RemoveLoading = false
              return false
            } else if (this._isType(options.resMsg, 'Function')) {
              msg = options.resMsg.call(dataTable, callRes)
            }
          }

          if (!msg && this._isType(resMsg, 'Function')) msg = resMsg.call(dataTable, options)
          if (!msg && this._isType(resMsg2, 'Function')) msg = resMsg2(options)
          if (!msg) msg = this._isType(resMsg3, 'Function') ? resMsg3(options) : callRes[resMsg3]

          if (options.err) {
            this.$message.error(msg || '删除失败')
          } else {
            this.$message.success(msg || '删除成功')
          }
        }

        options.item.RemoveLoading = false
      } else {
        if (typeof options.index != 'undefined') {
          dataTable.items.splice(options.index, 1)
          if (dataTable.total > 0) dataTable.total -= 1
        }

        if (this._isType(options.callback, 'Function')) {
          await options.callback.call(this, options)
        }

        options.item.RemoveLoading = false
      }
    },
    // 导出数据表格
    async DataTableExport (options = {}) {
      this._dataTableInit(options.tableName)
      let dataTable = this.DataTables[options.tableName]

      options.reqOptions = options.reqOptions || {}
      options.reqOptions.url = options.reqOptions.url || dataTable.exportUrl
      if (!options.reqOptions.url) return false

      if (this.$vue2xcrudConfig && this._isType(this.$vue2xcrudConfig.reqOptions, 'Object')) options.reqOptions = Object.assign({}, this.$vue2xcrudConfig.reqOptions, options.reqOptions)
      options.reqOptions.params = this._isType(options.reqOptions.params, 'Object') ? Object.assign(dataTable.queryParams, options.reqOptions.params) : (options.reqOptions.params || dataTable.queryParams)
      options.reqOptions.method = options.reqOptions.method || 'get'
      options.reqOptions.responseType = options.reqOptions.responseType || 'blob'

      if (options.noConfirm != true) {
        let confirmRes = ''
        try {
          confirmRes = await this.$confirm(options.confirmText || '确认导出数据？', '警告', { type: 'warning' })
        } catch(err) {
          confirmRes = err
        }
        if (confirmRes != 'confirm') return false
      }

      dataTable.exportLoading = true

      if (this._isType(this.SetDataTableExport, 'Function')) {
        try {
          if (await this.SetDataTableExport.call(dataTable, options) == false) {
            dataTable.exportLoading = false
            return false
          }
        } catch(err) {
          console.error(err)
          this.$message.error('请检查 SetDataTableExport 回调方法中逻辑或语法错误！')
          dataTable.exportLoading = false
          return false
        }
      }

      try {
        options.res = await this.$vue2xcrudAxios(options.reqOptions)
      } catch(err) {
        options.err = err
      }

      if (!options.err) {
        let fileType = dataTable.exportRes && dataTable.exportRes.fileType
        let fileType2 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.exportFileType
        if (typeof fileType === 'undefined' && typeof fileType2 === 'undefined') {
          console.warn(`请在 vue2xcrudConfig.dataTable 中设定 exportFileType 或 DataTables.${options.tableName}.exportRes 中设定 fileType`)
        }
        let downloadFileType = this._isType(fileType, 'Function') ? fileType.call(dataTable, options['res']) : this._isType(fileType2, 'Function') ? fileType2(options['res']) : ''

        let fileName = dataTable.exportRes && dataTable.exportRes.fileName
        let fileName2 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.exportFileName
        let downloadFileName = this._isType(fileName, 'Function') ? fileName.call(dataTable, options['res']) : this._isType(fileName2, 'Function') ? fileName2(options['res']) : document.title + new Date().toLocaleString() + '.' + downloadFileType

        let fileData = dataTable.exportRes && dataTable.exportRes.fileData
        let fileData2 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.exportFileData
        if (typeof fileData === 'undefined' && typeof fileData2 === 'undefined') {
          console.warn(`请在 vue2xcrudConfig.dataTable 中设定 exportFileData 或 DataTables.${options.tableName}.exportRes 中设定 fileData`)
        }
        let downloadFileData = this._isType(fileData, 'Function') ? fileData.call(dataTable, options['res']) : this._isType(fileData2, 'Function') ? fileData2(options['res']) : ''

        if (downloadFileData) {
          this._downloadFromBlob({
            fileName: downloadFileName,
            fileData: downloadFileData,
            fileType: downloadFileType
          })
        } else {
          options.err = true
        }
      }

      if (this.$vue2xcrudConfig && this.$vue2xcrudConfig.resMsg == true && options.resMsg != false) {
        let resMsg = dataTable.exportRes && dataTable.exportRes.resMsg
        let resMsg2 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.exportResMsg
        let resMsg3 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.resMsgKey

        let msg = ''
        let callRes = ''
        if (options.err) {
          callRes = options['err']
          if (options.resMsg == false || options.errMsg == false) {
            dataTable.exportLoading = false
            return false
          } else if (this._isType(options.errMsg, 'Function')) {
            msg = options.errMsg.call(dataTable, callRes)
          }
        } else {
          callRes = options['res']
          if (options.resMsg == false) {
            dataTable.exportLoading = false
            return false
          } else if (this._isType(options.resMsg, 'Function')) {
            msg = options.resMsg.call(dataTable, callRes)
          }
        }

        if (!msg && this._isType(resMsg, 'Function')) msg = resMsg.call(dataTable, options)
        if (!msg && this._isType(resMsg2, 'Function')) msg = resMsg2(options)
        if (!msg) msg = this._isType(resMsg3, 'Function') ? resMsg3(options) : callRes[resMsg3]

        if (options.err) {
          this.$message.error(msg || '导出失败')
        } else {
          this.$message.success(msg || '导出成功')
        }
      }

      dataTable.exportLoading = false
    },
    async DataTableSwitch (options = {}) {
      this._dataTableInit(options.tableName)
      let dataTable = this.DataTables[options.tableName]

      options.item = this._isType(options.item, 'Object') ? options.item : {}
      this.$set(options.item, 'SwitchLoading', true)

      if (dataTable.switchUrl || (options.reqOptions && options.reqOptions.url)) {
        options.reqOptions = options.reqOptions || {}
        if (this.$vue2xcrudConfig && this._isType(this.$vue2xcrudConfig.reqOptions, 'Object')) options.reqOptions = Object.assign({}, this.$vue2xcrudConfig.reqOptions, options.reqOptions)
        options.reqOptions.url = options.reqOptions.url || (options.reqOptions.params ? dataTable.switchUrl : dataTable.switchUrl + options.id)
        options.reqOptions.method = options.reqOptions.method || 'put'

        if (this._isType(this.SetDataTableSwitch, 'Function')) {
          try {
            if (await this.SetDataTableSwitch.call(dataTable, options) == false) {
              options.item.SwitchLoading = false
              return false
            }
          } catch(err) {
            console.error(err)
            this.$message.error('请检查 SetDataTableSwitch 回调方法中逻辑或语法错误！')
            options.item.SwitchLoading = false
            return false
          }
        }

        try {
          options.res = await this.$vue2xcrudAxios(options.reqOptions)
        } catch(err) {
          options.err = err
        }

        if (!options.err) {
          this.$set(options.item, 'SwitchStatus', !options.item.SwitchStatus) // SwitchStatus 需要外部构造 Boolean 类型
        }

        if (this._isType(options.callback, 'Function')) {
          await options.callback.call(dataTable, options)
        }

        if (this.$vue2xcrudConfig && this.$vue2xcrudConfig.resMsg == true && options.resMsg != false) {
          let resMsg = dataTable.switchRes && dataTable.switchRes.resMsg
          let resMsg2 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.switchResMsg
          let resMsg3 = this.$vue2xcrudConfig && this.$vue2xcrudConfig.resMsgKey

          let msg = ''
          let callRes = ''
          if (options.err) {
            callRes = options['err']
            if (options.resMsg == false || options.errMsg == false) {
              options.item.SwitchLoading = false
              return false
            } else if (this._isType(options.errMsg, 'Function')) {
              msg = options.errMsg.call(dataTable, callRes)
            }
          } else {
            callRes = options['res']
            if (options.resMsg == false) {
              options.item.SwitchLoading = false
              return false
            } else if (this._isType(options.resMsg, 'Function')) {
              msg = options.resMsg.call(dataTable, callRes)
            }
          }

          if (!msg && this._isType(resMsg, 'Function')) msg = resMsg.call(dataTable, options)
          if (!msg && this._isType(resMsg2, 'Function')) msg = resMsg2(options)
          if (!msg) msg = this._isType(resMsg3, 'Function') ? resMsg3(options) : callRes[resMsg3]

          if (options.err) {
            this.$message.error(msg || '设置失败')
          } else {
            this.$message.success(msg || '设置成功')
          }
        }

        options.item.SwitchLoading = false
      } else {
        this.$set(options.item, 'SwitchStatus', !options.item.SwitchStatus)

        if (this._isType(options.callback, 'Function')) {
          await options.callback.call(this, options)
        }

        options.item.SwitchLoading = false
      }
    },
    GetDict (dict, options) {
      if (!dict) return false
      let findRes = []
      if (this._isType(options, 'Object')) {
        if (!options.reqKey || !options.reqVal || !options.resKey) return false
        if (this._isType(options.reqVal, 'Array')) {
          options.reqVal.forEach((item) => {
            let res = dict.find(item2 => item2[options.reqKey] == '' + item)
            if (res) findRes.push(res[options.resKey])
          })
        } else {
          // '' + value转字符串，防止数字0==''
          let res = dict.find(item => {
            return item[options.reqKey] == '' + options.reqVal
          })
          findRes = res && res[options.resKey]
        }
      } else {
        if (this._isType(options, 'Array')) {
          options.forEach((item) => {
            let res = dict.find(item2 => item2.value == '' + item)
            if (res) findRes.push(res['label'])
          })
        } else {
          // '' + value转字符串，防止数字0==''
          let res = dict.find(item => {
            return item.value == '' + options
          })
          findRes = res && res['label']
        }
      }
      return findRes
    },
    _dataTableInit (k) {
      if (!this._isType(this.DataTables[k], 'Object')) this.$set(this.DataTables, k, {})

      if (typeof this.DataTables[k]['loading'] === 'undefined') this.$set(this.DataTables[k], 'loading', false)
      if (typeof this.DataTables[k]['exportLoading'] === 'undefined') this.$set(this.DataTables[k], 'exportLoading', false)
      if (typeof this.DataTables[k]['queryParams'] === 'undefined') this.$set(this.DataTables[k], 'queryParams', {})

      if (typeof this.DataTables[k]['pageNumName'] === 'undefined' && this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.pageNumName) {
        this.$set(this.DataTables[k], 'pageNumName', this.$vue2xcrudConfig.dataTable.pageNumName)
      }
      if (typeof this.DataTables[k]['pageSizeName'] === 'undefined' && this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.pageSizeName) {
        this.$set(this.DataTables[k], 'pageSizeName', this.$vue2xcrudConfig.dataTable.pageSizeName)
      }

      if (typeof this.DataTables[k]['queryParams'][ this.DataTables[k]['pageNumName'] ] === 'undefined' && this.DataTables[k]['pageNumName']) {
        this.$set(this.DataTables[k]['queryParams'], this.DataTables[k]['pageNumName'], (this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.pageNum) || 1)
      }
      if (typeof this.DataTables[k]['queryParams'][ this.DataTables[k]['pageNumName'] ] === 'undefined') {
        console.warn(`请在 vue2xcrudConfig 或 DataTables.${k} 中设定 pageNumName`)
      }

      if (typeof this.DataTables[k]['queryParams'][ this.DataTables[k]['pageSizeName'] ] === 'undefined' && this.DataTables[k]['pageSizeName']) {
        this.$set(this.DataTables[k]['queryParams'], this.DataTables[k]['pageSizeName'], (this.$vue2xcrudConfig && this.$vue2xcrudConfig.dataTable && this.$vue2xcrudConfig.dataTable.pageSize) || 10)
      }
      if (typeof this.DataTables[k]['queryParams'][ this.DataTables[k]['pageSizeName'] ] === 'undefined') {
        console.warn(`请在 vue2xcrudConfig 或 DataTables.${k} 中设定 pageSizeName`)
      }

      if (typeof this.DataTables[k]['items'] === 'undefined') this.$set(this.DataTables[k], 'items', [])
      if (typeof this.DataTables[k]['total'] === 'undefined') this.$set(this.DataTables[k], 'total', 0)

      return this.DataTables[k]
    },
    _isType (obj, type) {
      return Object.prototype.toString.call(obj) === '[object ' + type + ']'
    },
    _downloadFromBlob (options = {}) {
      if (!options.fileName || !options.fileData) return false
      let $A = document.createElement('a')
      $A.download = options.fileName
      $A.style.display = 'none'
      $A.href = URL.createObjectURL(new Blob([options.fileData], { type: options.fileType }))
      document.body.appendChild($A)
      $A.click()
      document.body.removeChild($A)
    },
  }
}