import {get, post, del, put, getFile } from "../http"
export default {
    // 销售明细表
    sale_report_index(p) {
        return get('api/report/sale/index', p);
    },
    sale_report_index_excel(p) {
        return getFile('api/report/sale/index_excel', p);
    },
    // 销售汇总表(按类别)
    sale_report_cate(p) {
        return get('api/report/sale/class', p);
    },
    // 销售汇总表(按类别)
    sale_report_cate_excel(p) {
        return getFile('api/report/sale/class_excel', p);
    },
    // 销售汇总表(按类别)
    sale_report_goods(p) {
        return get('api/report/sale/goods', p);
    },
    // 销售汇总表(按类别)
    sale_report_goods_excel(p) {
        return getFile('api/report/sale/goods_excel', p);
    },

    // 销售汇总表(按客户)
    sale_report_custom(p) {
        return get('api/report/sale/customer', p);
    },
    // 销售汇总表(按类别)
    sale_report_custom_excel(p) {
        return getFile('api/report/sale/customer_excel', p);
    },

    // 销售利润表
    sale_report_profit(p) {
        return get('api/report/sale/profit', p);
    },
    sale_report_profit_excel(p) {
        return getFile('api/report/sale/profit_excel', p);
    },
}