import {get, post, del, put, getFile } from "../http"
export default {
    //
    coinLogList(p) {
        return get('api/capital/detailed/handle', p);
    },
    // 导出excel
    coinLogExcel(p) {
        return getFile('api/capital/detailed/excel', p);
    }
}