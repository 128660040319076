import {get, post, del, put, getFile } from "../http"
export default {
    // 销售明细表
    finance_customer(p) {
        return get('api/report/finance/customer', p);
    },
    finance_customer_source() {
        return get('api/report/finance/customer_source');
    },
    finance_customer_excel(p) {
        return getFile('api/report/finance/customer_excel', p);
    },
    finance_supplier(p) {
        return get('api/report/finance/supplier', p);
    },
    finance_supplier_source(p) {
        return get('api/report/finance/supplier_source', p);
    },
    finance_supplier_excel(p) {
        return getFile('api/report/finance/supplier_excel', p);
    }
}