<template>
  <div class="AppTop">
    <template>
      <div class="commonTopHead" v-if="$platform != '1688'">{{ SetUpInfo.banner }}</div>
      <AliTopHead v-if="$platform == '1688'" />
    </template>
    <el-tabs class="menuTabs" :value="CurrentMenuTab" type="card" :closable="MenuTabs.length > 1" @tab-click="menuTabClick" @tab-remove="menuTabRemove">
      <el-tab-pane v-for="(item, index) in MenuTabs" :key="item" :label="item" :name="item" />
    </el-tabs>
  </div>
</template>

<script>
import tools from '@/extends/tools.js'
import apis from '@/views/common/js/api2'

import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState(['SetUpInfo', 'CurrentMenuTab', 'MenuTabs']),
    ...mapMutations(['SetCurrentMenuTab', 'SetMenuTabs'])
  },
  components: {
    AliTopHead: () => import('./components/AliTopHead.vue')
  },
  methods: {
    menuTabClick({ name } = tab) {
      if (name != this.$router.currentRoute.name) {
        this.$router.push({ name })
      }
    },
    menuTabRemove(name) {
      let currentMenuTabs = this.MenuTabs.filter(item => item != name)
      this.$store.commit('SetMenuTabs', currentMenuTabs)  // 因 MenuTabs 在页面上绑定所以需要 commit，不能直接 state 赋值
      localStorage.setItem('MenuTabs', JSON.stringify(currentMenuTabs))

      if (name == this.CurrentMenuTab) {
        let currentMenuTab = currentMenuTabs[currentMenuTabs.length - 1]
        this.$store.commit('SetCurrentMenuTab', currentMenuTab)
        this.$router.push({ name: currentMenuTab })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .AppTop {
    .commonTopHead {height:40px;line-height:40px;color:#000;font-size:16px;padding:0 10px;box-shadow:0 0 5px #ddd;}
    .menuTabs {border-bottom:1px solid #E4E7ED;padding:0 10px;margin-top:10px;
      /deep/ .el-tabs__header {margin:0;border-bottom:0;}
      /deep/ .el-tabs__nav-next {line-height:40px;}
      /deep/ .el-tabs__nav-prev {line-height:40px;}
    }
  }
</style>
