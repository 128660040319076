import {get, post, del, put, getFile } from "../http"
export default {

    // 绑定组合时获取本地组合列表
    getGroupListBindSku(p) {
        return get('/api/goods/binding/combination', p);
    },
    // 组合获取sku列表
    getSkuListLocalGroup(p) {
        return get('/api/goods/combination_sku', p);
    },
    // 组合列表
    getGoodsGroupsList(p) {
        return get('/api/goods/combination', p);
    },
    // 组合列表
    getGoodsGroupsDetail(p) {
        return get('/api/goods/combination/' + p.id, p);
    },
    addGoodsGroups(p, type = 1) {
        p.type = type;
        return post('api/goods/combination', p);
    },
    updateGoodsGroups(p, type = 1) {
        p.type = type;
        return put('api/goods/combination/' + p.id, p);
    },
    // 删除商品组合
    deleteGoodsGroups(p) {
        return del('api/goods/combination/' + p.id);
    },
    // 数据源
    getGoodsGroupsSource() {
        return get('api/goods/combination_source');
    },
    //  用户用以组合的sku列表
    getGoodsGroupsLocalSku(p) {
        return get('api/goods/combination_sku', p);
    },

    // 获取线上商品
    getGoodsOnline(p) {
        return get('/api/goods/binding/index', p);
    },
    // 绑定商品页面的数据源
    getBindSource(p) {
        return get('/api/goods/binding/source', p);
    },
    // 刷新商品列表
    syncGoodsList(p) {
        return post('/api/goods/binding/synchro', p);
    },
    // 获取本地SKU列表
    getLocalSkuList(p) {
        return get('/api/goods/binding/local_sku', p);
    },
    // 捆绑操作(新增、更改)
    bindGoodsOnlineWithLocal(p) {
        return put('/api/goods/binding/update/' + p.sku_id, p);
    },
    // 绑定详情
    bindGoodsDetail(p) {
        return get('/api/goods/binding/details/' + p.sku_id, p);
    },
    // 从线上下载商品，生成本地sku并绑定
    bindGoodsOnlineDownload(p) {
        return post('/api/goods/binding/synchronization', p);
    },
    exportBinding(p) {
        return getFile('/api/goods/binding/excel', p);
    },
    // 商品分类
    goodsCateList(p) {
        return get('/api/goods/goodsclassify', p);
    },
    addGoodsCate(p) {
        return post('/api/goods/goodsclassify', p);
    },
    goodsCateDetail(p) {
        return get('/api/goods/goodsclassify/' + p.id, p);
    },
    goodsCateDelete(p) {
        return del('/api/goods/goodsclassify/' + p.id, p);
    },
    goodsCateUpdate(p) {
        return put('/api/goods/goodsclassify/' + p.id, p);
    },

    // 商品属性
    goodsAttrList(p) {
        return get('/api/goods/goodsattribute', p);
    },
    addGoodsAttr(p) {
        return post('/api/goods/goodsattribute', p);
    },
    goodsAttrDetail(p) {
        return get('/api/goods/goodsattribute/' + p.id, p);
    },
    goodsAttrDelete(p) {
        return del('/api/goods/goodsattribute/' + p.id, p);
    },
    goodsAttrUpdate(p) {
        return put('/api/goods/goodsattribute/' + p.id, p);
    },

    // 商品单位库
    goodsUnitList(p) {
        return get('/api/goods/goodunit', p);
    },
    addGoodsUnit(p) {
        return post('/api/goods/goodunit', p);
    },
    goodsUnitDetail(p) {
        return get('/api/goods/goodunit/' + p.id, p);
    },
    goodsUnitDelete(p) {
        return del('/api/goods/goodunit/' + p.id, p);
    },
    goodsUnitUpdate(p) {
        return put('/api/goods/goodunit/' + p.id, p);
    },
    // 商品标签
    goodsLabelList(p) {
        return get('/api/goods/goodlabel', p);
    },
    addGoodsLabel(p) {
        return post('/api/goods/goodlabel', p);
    },
    goodsLabelDetail(p) {
        return get('/api/goods/goodlabel/' + p.id, p);
    },
    goodsLabelDelete(p) {
        return del('/api/goods/goodlabel/' + p.id, p);
    },
    goodsLabelUpdate(p) {
        return put('/api/goods/goodlabel/' + p.id, p);
    },
    // 商品信息
    goodsList(p) {
        return get('/api/goods/handle', p);
    },
    addGoods(p) {
        return post('/api/goods/handle', p);
    },
    goodsDetail(p) {
        return get('/api/goods/handle/' + p.id, p);
    },
    goodsDelete(p) {
        return del('/api/goods/handle/' + p.id, p);
    },
    goodsUpdate(p) {
        return post('/api/goods/handle/' + p.id, p);
    },
    //添加商品时各种数据源，属性，单位，规格等
    goodsSource() {
        return get('/api/goods/source');
    }


}