import {get, post, del, put, getFile } from "../http"
export default {
    // 添加借出单
    lendAdd(p) {
        return post('api/lend/handle', p);
    },
    lendList(p) {
        return get('api/lend/handle', p);
    },
    // 借出单详情
    lendDetail(p) {
        return get('api/lend/handle/' + p.id, p);
    },
    // 修改借出单
    lendSave(p) {
        return put('api/lend/handle/' + p.id, p);
    },
    // 添加借出单
    lendDelete(p) {
        return del('api/lend/handle/' + p.id, p);
    },
    //各种数据源
    lendSource(p) {
        return get('api/lend/source', p);
    },
    // 商品弹窗
    lendhandleGoods(p) {
        return get('api/lend/goods', p);
    },
    // 导出excel
    lendExcel(p) {
        return getFile('api/lend/excel', p);
    },
    // 归还
    lendBack(p) {
        return post('api/lend/ret/' + p.id, p);
    },
    //上传文件
    lend_file(p) {
        return post('api/admin/file', p);
    }
}